<table class="wrap-content" [attr.data-testid]="'coupon-bottom-dialog'">
    <tr *ngFor="let coupon of coupons; trackBy: identify let i = index"
        [id]="coupon.couponId + '_' + getPrefixCouponStatus(coupon.couponInfo)" #couponCard
        [attr.data-testid]="'coupon-list-card'" class="scroll-margin-top">

        <td class="coupon-column wrap-coupon" [attr.data-testid]="'link-image-coupon-detail-' + i"
            [ngClass]="{
                'padding-multiple': isDisplayMultipleCoupons(coupon.couponInfo[0].status, coupon.numberOfCoupons, coupon.couponInfo[0].receivedOrder) ? displayNumberOfQuota(coupon.couponInfo[0].status, coupon.repetitiveStatus, coupon.numberOfTemporaryOutOfQuotaCoupon, coupon.numberOfOutOfQuotaCoupon,coupon.maximumRepetitiveCouponPerUser, coupon.maximumCouponPerUser) > 1 : coupon.numberOfCoupons > 1 }"
            (click)="coupon.couponInfo[0].status !== CouponStatus.NEW && coupon.couponInfo[0].status !== CouponStatus.OUT_OF_QUOTA && coupon.couponInfo[0].status !== CouponStatus.TEMPO_OUT_OF_QUOTA && coupon.numberOfCoupons > 1 ? openOverlay(coupon) : navigateToCouponDetail(coupon)">

            <!-- Box shadow check multiple coupon :D -->
            <img *ngIf="isDisplayMultipleCoupons(coupon.couponInfo[0].status, coupon.numberOfCoupons, coupon.couponInfo[0].receivedOrder) ? displayNumberOfQuota(coupon.couponInfo[0].status, coupon.repetitiveStatus, coupon.numberOfTemporaryOutOfQuotaCoupon, coupon.numberOfOutOfQuotaCoupon, coupon.maximumRepetitiveCouponPerUser, coupon.maximumCouponPerUser) > 1 : coupon.numberOfCoupons > 1"
                class="mask-left-shadow" [src]="coupon.couponImage" loading="lazy"
                [attr.data-testid]="'link-coupon-list-detail' + i">

            <img class="mask-left" [src]="coupon.couponImage" loading="lazy"
                [attr.data-testid]="'link-coupon-list-detail' + i">

            <!-- Stamp coupon need to comfirm status with BE -->
            <div [ngClass]="{'stamp-used': coupon.couponInfo[0].status === CouponStatus.REDEEM_CONFIRMED, 
                    'stamp-expired':  coupon.couponInfo[0].status === CouponStatus.EXPIRED, 
                    'stampsold-out': coupon.couponInfo[0].status === CouponStatus.OUT_OF_QUOTA || coupon.couponInfo[0].status === CouponStatus.OUT_OF_USED_QUOTA,
                    'stamp-tempo-sold-out': coupon.couponInfo[0].status === CouponStatus.TEMPO_OUT_OF_QUOTA,
                    'stamp-unconditional': coupon.couponInfo[0].status === CouponStatus.NOT_ELIGIBLE_TO_USE }">
            </div>

            <!-- Badge Level parameter with BE -->
            <ng-container *ngIf="isDisplayBadgeLabel(coupon.couponInfo[0].status)">
                <div class="badge badge-green" *ngIf="coupon.badgeLabel === BadgeLabel.NEW">New</div>
                <div class="badge badge-red" *ngIf="coupon.badgeLabel === BadgeLabel.HOT">Hot</div>
            </ng-container>
        </td>

        <td class="wrap-coupon">
            <div class="shadow-content">
                <!-- Box shadow check multiple coupon :D -->
                <div *ngIf="isDisplayMultipleCoupons(coupon.couponInfo[0].status, coupon.numberOfCoupons, coupon.couponInfo[0].receivedOrder) ? displayNumberOfQuota(coupon.couponInfo[0].status, coupon.repetitiveStatus, coupon.numberOfTemporaryOutOfQuotaCoupon, coupon.numberOfOutOfQuotaCoupon, coupon.maximumRepetitiveCouponPerUser, coupon.maximumCouponPerUser) > 1 : coupon.numberOfCoupons > 1"
                    class="box-content mask-right-shadow"></div>

                <div class="box-content mask-right">
                    <div class="padding-content box-top-height" [attr.data-testid]="'link-title-coupon-detail-' + i"
                        (click)="coupon.couponInfo[0].status !== CouponStatus.NEW && coupon.couponInfo[0].status !== CouponStatus.OUT_OF_QUOTA && coupon.couponInfo[0].status !== CouponStatus.TEMPO_OUT_OF_QUOTA && coupon.numberOfCoupons > 1 ? openOverlay(coupon) : navigateToCouponDetail(coupon)">
                        <!-- -->
                        <h4 class="newline-wrap coupon-title" [id]="'title-'+i"
                            [ngClass]="{'title-multiple': isDisplayMultipleCoupons(coupon.couponInfo[0].status, coupon.numberOfCoupons, coupon.couponInfo[0].receivedOrder) ? displayNumberOfQuota(coupon.couponInfo[0].status, coupon.repetitiveStatus, coupon.numberOfTemporaryOutOfQuotaCoupon, coupon.numberOfOutOfQuotaCoupon, coupon.maximumRepetitiveCouponPerUser, coupon.maximumCouponPerUser) > 1 : coupon.numberOfCoupons > 1 }"
                            style="--gap: {{badgeNumber.offsetWidth}}px">{{coupon.couponShortTitle}}
                        </h4>
                        <span class="newline-wrap coupon-description"
                            [id]="'description-'+i">{{coupon.couponSubtitle}}</span>

                        <!-- Check logic progress bar >= 80 && <= 99 and check parameter with BE -->
                        <div class="section-progress"
                            *ngIf="isDisplayProgressBarLabel(coupon.couponInfo[0].status) && !coupon.isHiddenProgressBar && coupon.progressionPercent >= 80 && coupon.progressionPercent <= 99">
                            <div
                                *ngIf="(coupon.deductionType === DeductionType.COLLECTED && coupon.couponInfo[0].status === CouponStatus.NEW) || coupon.deductionType === DeductionType.REDEEMED">
                                <div class="is-inline">
                                    <progress class="progress orange" [value]="coupon.progressionPercent"
                                        max="100"></progress>
                                    <div class="is-inline-block v-align-top">
                                        <div class="pl-2">
                                            <span class="text-progress">{{coupon.couponInfo[0].status ===
                                                CouponStatus.COLLECTED ? (
                                                "ใช้แล้ว"
                                                ) : (
                                                coupon.deductionType === "collected" ?
                                                ( "เก็บแล้ว" ) : ( "ใช้แล้ว" )
                                                )}}</span>
                                            <span class="text-progress-num orange">
                                                {{coupon.progressionPercent}}%</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="box-bottom">
                        <table class="wrap-content"
                            [class.table-fixed]="coupon.couponInfo[0].status === CouponStatus.NOT_ELIGIBLE_TO_USE">
                            <tr>
                                <td class="v-align-bottom" width="80%"
                                    [class.coupon-info-ellipsis-text]="coupon.couponInfo[0].status === CouponStatus.NOT_ELIGIBLE_TO_USE"
                                    [attr.data-testid]="'link-card-coupon-detail-' + i"
                                    (click)="coupon.couponInfo[0].status !== CouponStatus.NEW && coupon.couponInfo[0].status !== CouponStatus.OUT_OF_QUOTA && coupon.couponInfo[0].status !== CouponStatus.TEMPO_OUT_OF_QUOTA && coupon.numberOfCoupons > 1 ? openOverlay(coupon) : navigateToCouponDetail(coupon)">
                                    <!-- Check status display bottom text with parameter from BE  -->
                                    <span class="font-light expire-text" [attr.data-testid]="'coupon-date-' + i"
                                        *ngIf="coupon.couponInfo[0].status === CouponStatus.REDEEM_CONFIRMED">
                                        <!-- Need to check parameter of used date -->
                                        วันที่ใช้ {{convertDateFormat(coupon.couponInfo[0].redeemedDate)}}
                                    </span>

                                    <span class="font-light expire-text" [attr.data-testid]="'coupon-date-' + i"
                                        *ngIf="coupon.couponInfo[0].status === CouponStatus.OUT_OF_QUOTA">
                                        ถูกเก็บหมดแล้ว
                                    </span>

                                    <span class="font-light expire-text" [attr.data-testid]="'coupon-date-' + i"
                                        *ngIf="coupon.couponInfo[0].status === CouponStatus.OUT_OF_USED_QUOTA">
                                        ถูกใช้หมดแล้ว
                                    </span>

                                    <span class="font-light disabled-interactive-text"
                                        [attr.data-testid]="'coupon-date-' + i"
                                        *ngIf="coupon.couponInfo[0].status === CouponStatus.TEMPO_OUT_OF_QUOTA">
                                        กดเพื่อดูเงื่อนไขการเก็บคูปองในครั้งถัดไป
                                    </span>

                                    <ng-container *ngIf="coupon.couponInfo[0].status === CouponStatus.NEW">
                                        <ng-container
                                            *ngIf="coupon.couponInfo[0].periodStatus === PeriodStatus.ALLOWED">
                                            <span class="font-light expire-text" [attr.data-testid]="'coupon-date-' + i"
                                                [id]="'showDate' + i">
                                                หมดอายุ {{ convertDateFormat(coupon.couponInfo[0].couponEndDatetime)
                                                + ' ' +
                                                convertTimeFormat(coupon.couponInfo[0].couponEndDatetime) + ' น.' }}
                                            </span>
                                        </ng-container>

                                        <ng-container *ngIf="coupon.couponInfo[0].periodStatus === PeriodStatus.PERIOD">
                                            <span class="font-light disabled-interactive-text"
                                                [attr.data-testid]="'coupon-date-' + i" [id]="'showDate' + i">
                                                เริ่มเก็บได้ {{
                                                convertTimeFormat(coupon.couponInfo[0].couponStartDatetime)+ '-' +
                                                convertTimeFormat(coupon.couponInfo[0].couponEndDatetime) + ' น.' }}
                                            </span>
                                        </ng-container>

                                        <ng-container *ngIf="coupon.couponInfo[0].periodStatus === PeriodStatus.DATE">
                                            <span class="font-light disabled-interactive-text"
                                                [attr.data-testid]="'coupon-date-' + i" [id]="'showDate' + i">
                                                เริ่มเก็บได้ {{
                                                convertDateFormat(coupon.couponInfo[0].couponStartDatetime) }}
                                            </span>
                                        </ng-container>

                                        <ng-container
                                            *ngIf="coupon.couponInfo[0].periodStatus === PeriodStatus.START_COLLECT">
                                            <span class="font-light disabled-interactive-text"
                                                [attr.data-testid]="'coupon-date-' + i" [id]="'showDate' + i">
                                                เริ่มเก็บได้ {{
                                                convertTimeFormat(coupon.couponInfo[0].couponStartDatetime) + ' น.'
                                                }}
                                            </span>
                                        </ng-container>
                                    </ng-container>

                                    <ng-container *ngIf="coupon.couponInfo[0].status === CouponStatus.COLLECTED">
                                        <ng-container *ngIf="isInChannel(coupon) else notInChannel">
                                            <ng-container
                                                *ngIf="coupon.couponInfo[0].periodStatus === PeriodStatus.ALLOWED">
                                                <span class="font-light expire-text"
                                                    [attr.data-testid]="'coupon-date-' + i" [id]="'showDate' + i">
                                                    หมดอายุ {{
                                                    convertDateFormat(coupon.couponInfo[0].couponEndRedemptionDateTime)
                                                    + ' ' +
                                                    convertTimeFormat(coupon.couponInfo[0].couponEndRedemptionDateTime)
                                                    + ' น.' }}
                                                </span>
                                            </ng-container>

                                            <ng-container
                                                *ngIf="coupon.couponInfo[0].periodStatus === PeriodStatus.PERIOD">
                                                <span class="font-light disabled-interactive-text"
                                                    [attr.data-testid]="'coupon-date-' + i" [id]="'showDate' + i">
                                                    เริ่มใช้ได้ {{
                                                    convertTimeFormat(coupon.couponInfo[0].couponStartRedemptionDatetime)
                                                    + '-' +
                                                    convertTimeFormat(coupon.couponInfo[0].couponEndRedemptionDateTime)
                                                    + ' น.' }}
                                                </span>
                                            </ng-container>

                                            <ng-container
                                                *ngIf="coupon.couponInfo[0].periodStatus === PeriodStatus.DATE">
                                                <span class="font-light disabled-interactive-text"
                                                    [attr.data-testid]="'coupon-date-' + i" [id]="'showDate' + i">
                                                    เริ่มใช้ได้ {{
                                                    convertDateFormat(coupon.couponInfo[0].couponStartRedemptionDatetime)
                                                    }}
                                                </span>
                                            </ng-container>

                                            <ng-container
                                                *ngIf="coupon.couponInfo[0].periodStatus === PeriodStatus.START_REDEEM">
                                                <span class="font-light disabled-interactive-text"
                                                    [attr.data-testid]="'coupon-date-' + i" [id]="'showDate' + i">
                                                    เริ่มใช้ได้ {{
                                                    convertTimeFormat(coupon.couponInfo[0].couponStartRedemptionDatetime)
                                                    + ' น.' }}
                                                </span>
                                            </ng-container>

                                        </ng-container>

                                        <ng-template #notInChannel>
                                            <span class="font-light disabled-interactive-text"
                                                [attr.data-testid]="'coupon-date-' + i">
                                                ไม่สามารถใช้กับช่องทางนี้ได้
                                            </span>
                                        </ng-template>
                                    </ng-container>

                                    <span class="font-light expire-text"
                                        *ngIf="(coupon.couponInfo[0].status === CouponStatus.REDEEMED || coupon.couponInfo[0].status === CouponStatus.EXPIRED)"
                                        [attr.data-testid]="'coupon-date-' + i" [id]="'showDate' + i">
                                        หมดอายุ {{
                                        convertDateFormat(coupon.couponInfo[0].couponEndRedemptionDateTime) + ' ' +
                                        convertTimeFormat(coupon.couponInfo[0].couponEndRedemptionDateTime) + ' น.'
                                        }}
                                    </span>

                                    <span class="font-light expire-text" [attr.data-testid]="'coupon-date-' + i"
                                        [id]="'showDate' + i"
                                        *ngIf="coupon.couponInfo[0].status === CouponStatus.NOT_ELIGIBLE_TO_USE">
                                        {{coupon.couponInfo[0].segmentErrorMessage}}
                                    </span>
                                </td>

                                <td *ngIf="isDisplayButton(coupon.couponInfo[0].status)">
                                    <!-- Allow Action -->
                                    <button *ngIf="coupon.couponInfo[0].status == CouponStatus.REDEEMED"
                                        class="button font-bold btn-coupon btn-redeem is-pulled-right disabled-action-button"
                                        [attr.data-testid]="'btn-collected-redeem-' + i"
                                        [id]="'btn-collected-redeem-' + i"
                                        (click)="coupon.numberOfCoupons > 1 ? openOverlay(coupon) : useCoupon(coupon, i)">
                                        {{ coupon.numberOfCoupons > 1 ? 'ใช้' : 'ดูโค้ด' }}
                                    </button>

                                    <ng-container
                                        *ngIf="coupon.couponInfo[0].periodStatus === PeriodStatus.ALLOWED else disabledAction">
                                        <button *ngIf="coupon.couponInfo[0].status == CouponStatus.NEW"
                                            class="button font-bold btn-coupon btn-collect is-pulled-right disabled-action-button "
                                            [id]="'btn-collect-' + coupon.couponId"
                                            [attr.data-testid]="'btn-collect-' + i"
                                            (click)="pickCoupon(coupon.couponId, coupon.couponInfo[0].receivedOrder, coupon.campaignCode, coupon.couponShortTitle, i)">
                                            <div class="font-bold">เก็บ</div>
                                        </button>

                                        <ng-container
                                            *ngIf="coupon.couponUseMethod === CouponMethod.COPAY else QRCoupon">
                                            <ng-container *ngIf="isInChannel(coupon);  else NotInChannel">

                                                <ng-container
                                                    *ngIf="coupon.couponInfo[0].status == CouponStatus.COLLECTED">

                                                    <ng-container
                                                        *ngIf="coupon.isUseButtonDisplay && !coupon.isCoPayNavigate">
                                                        <a #pickCouponCallbackUrl
                                                            class="is-hidden button font-bold btn-coupon btn-redeem is-pulled-right"
                                                            [id]="'btn-redeem-' + i"
                                                            [attr.data-testid]="'btn-redeem-' + i"
                                                            [href]="paymentCallbackUrl.replace('${campaignId}', coupon.couponId) + '&callbackMode=H' | safe : 'url'"
                                                            (click)="setStepRouteFlag(coupon.couponId)">
                                                            ใช้
                                                        </a>

                                                        <!-- Used copay -->
                                                        <button
                                                            class="button font-bold btn-coupon btn-redeem is-pulled-right disabled-action-button"
                                                            [attr.data-testid]="'btn-collected-redeem-' + i"
                                                            [id]="'btn-collected-redeem-' + coupon.couponId"
                                                            (click)="coupon.numberOfCoupons > 1 ? openOverlay(coupon) : useScanCopayCoupon($event, coupon, coupon.couponInfo[0].receivedOrder, 'btn-redeem-' + i, i, 'ScanCoPay')">
                                                            ใช้
                                                        </button>
                                                    </ng-container>

                                                    <ng-container
                                                        *ngIf="!coupon.isUseButtonDisplay && coupon.isCoPayNavigate">
                                                        <ng-container
                                                            *ngIf="coupon.copayNavigation.navigateType === CopayNavigationType.INTERNAL_PT">
                                                            <!-- need check destination at ngif or at method -->
                                                            <button
                                                                *ngIf="coupon.copayNavigation.destination === InternalNavigationType.PT_PAYMENT || 
                                                                coupon.copayNavigation.destination === InternalNavigationType.PT_TOPUP"
                                                                class="button font-bold btn-coupon btn-redeem is-pulled-right disabled-action-button"
                                                                [attr.data-testid]="'btn-collected-redeem-' + i"
                                                                [id]="'btn-collected-redeem-' + coupon.couponId"
                                                                (click)="coupon.numberOfCoupons > 1 ? openOverlay(coupon) : usePaymentTopup(coupon,coupon.couponInfo[0].receivedOrder , i, 0, 'ScanCoPay')">
                                                                ใช้
                                                            </button>

                                                            <button
                                                                *ngIf="coupon.copayNavigation.destination !== InternalNavigationType.PT_PAYMENT && 
                                                                coupon.copayNavigation.destination !== InternalNavigationType.PT_TOPUP"
                                                                class="button font-bold btn-coupon btn-redeem is-pulled-right disabled-action-button"
                                                                [attr.data-testid]="'btn-collected-redeem-' + i"
                                                                [id]="'btn-collected-redeem-' + coupon.couponId"
                                                                (click)="coupon.numberOfCoupons > 1 ? openOverlay(coupon) : null">
                                                                ใช้
                                                            </button>
                                                        </ng-container>

                                                        <ng-container
                                                            *ngIf="coupon.copayNavigation.navigateType === CopayNavigationType.EXTERNAL_URL || 
                                                            coupon.copayNavigation.navigateType === CopayNavigationType.EXTERNAL_DEEPLINK">
                                                            <!-- external url  -->
                                                            <button
                                                                class="button font-bold btn-coupon btn-redeem is-pulled-right disabled-action-button"
                                                                [attr.data-testid]="'btn-collected-redeem-' + i"
                                                                (click)="coupon.numberOfCoupons > 1 ? openOverlay(coupon) : navigateExternalURL(coupon, i)">
                                                                ใช้
                                                            </button>
                                                        </ng-container>
                                                    </ng-container>

                                                </ng-container>
                                            </ng-container>

                                            <ng-template #NotInChannel>
                                                <button *ngIf="coupon.couponInfo[0].status == CouponStatus.COLLECTED"
                                                    class="button font-bold btn-coupon btn-redeem is-pulled-right disabled">ใช้
                                                </button>
                                            </ng-template>
                                        </ng-container>

                                        <ng-template #QRCoupon>
                                            <!-- Use QR Code -->
                                            <button *ngIf="coupon.couponInfo[0].status == CouponStatus.COLLECTED"
                                                class="button font-bold btn-coupon btn-redeem is-pulled-right disabled-action-button"
                                                [attr.data-testid]="'btn-collected-redeem-' + i"
                                                (click)="coupon.numberOfCoupons > 1 ? openOverlay(coupon) : useCoupon(coupon, i)">
                                                ใช้
                                            </button>
                                        </ng-template>

                                    </ng-container>

                                    <!-- Disabled Action -->

                                    <ng-template #disabledAction>

                                        <button
                                            *ngIf="coupon.couponInfo[0].status === CouponStatus.NEW && (coupon.couponInfo[0].periodStatus === PeriodStatus.PERIOD || coupon.couponInfo[0].periodStatus === PeriodStatus.DATE)"
                                            class="button font-bold btn-coupon btn-redeem is-pulled-right disabled"
                                            [attr.data-testid]="'btn-disable-collect-' + i">เก็บ
                                        </button>

                                        <button
                                            *ngIf="coupon.couponInfo[0].status === CouponStatus.COLLECTED && (coupon.couponInfo[0].periodStatus === PeriodStatus.PERIOD || coupon.couponInfo[0].periodStatus === PeriodStatus.DATE)"
                                            class="button font-bold btn-coupon btn-redeem is-pulled-right disabled"
                                            [attr.data-testid]="'btn-disable-redeem-' + i">ใช้
                                        </button>

                                    </ng-template>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
                <!-- number of coupons check multiple coupon :D -->

                <div class="badge-number" #badgeNumber
                    [hidden]="!(isDisplayMultipleCoupons(coupon.couponInfo[0].status, coupon.numberOfCoupons, coupon.couponInfo[0].receivedOrder) ? displayNumberOfQuota(coupon.couponInfo[0].status, coupon.repetitiveStatus, coupon.numberOfTemporaryOutOfQuotaCoupon, coupon.numberOfOutOfQuotaCoupon, coupon.maximumRepetitiveCouponPerUser, coupon.maximumCouponPerUser) > 1 : coupon.numberOfCoupons > 1)">
                    x{{ isDisplayMultipleCoupons(coupon.couponInfo[0].status, coupon.numberOfCoupons,
                    coupon.couponInfo[0].receivedOrder) ? displayNumberOfQuota(coupon.couponInfo[0].status,
                    coupon.repetitiveStatus, coupon.numberOfTemporaryOutOfQuotaCoupon,
                    coupon.numberOfOutOfQuotaCoupon,
                    coupon.maximumRepetitiveCouponPerUser, coupon.maximumCouponPerUser) : coupon.numberOfCoupons }}
                    <div class="triangle-shadow"></div>
                </div>
            </div>
        </td>
    </tr>
</table>