import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { filter, take } from 'rxjs';
import { modalWording, ResponseCode } from 'src/app/configs/app-config';
import { CouponCardTemplate } from 'src/app/configs/coupon-card-config';
import { PageRoute } from 'src/app/configs/route-config';
import { calculateTimeDiff, convertToLocaleDateString, convertToLocaleTimeString } from 'src/app/helpers/date-time.helper';
import { IModalTemplate } from 'src/app/model/coupon-modal.model';
import { SelectedCoupon } from 'src/app/root-store/root-store.model';
import { RootStoreService } from 'src/app/root-store/root-store.service';
import { ModalService } from 'src/app/service/modal.service';
import { CouponService } from 'src/shared/coupon.service';
import { ModalTemplateComponent } from '../../modal-template/modal-template.component';
import { isEmpty, isNil } from 'lodash';
import { AdditionalParams, BadgeLabel, CopayNavigationType, CouponActionType, CouponChannel, CouponEntryPoint, CouponMethod, CouponStatus, DeductionType, InternalNavigationType, PeriodStatus, UseActionTypeModalTemplate } from 'src/app/model/coupon-enum.model';
import { AnalyticCategory, AnalyticFirebaseParam, AnalyticLabel, interfaceToNativeCommand, interfaceToNativeContentDestination } from 'src/app/webview-to-native-interface/webview-to-native-interface-enum.model';
import { checkRegisterStatus, checkRegisterStatusAndType, handleDisplayPermissionUse, handleErrorCouponStatus, mappingUserSOFWithCoupon } from 'src/app/helpers/coupon.helper';
import { FirebaseParam } from 'src/app/webview-to-native-interface/webview-to-native-interface.model';
import { IApplyCouponDetail, ICouponInfo, IPermissionUseCouponResponse } from 'src/app/model/coupon-model.model';
import { WebviewToNativeInterfaceService } from 'src/app/webview-to-native-interface/webview-to-native-interface.service';

@Component({
  selector: 'apply-coupon-card-horizontal-dialog',
  templateUrl: './apply-coupon-card-horizontal-dialog.component.html',
  styleUrls: ['./apply-coupon-card-horizontal-dialog.component.scss']
})
export class ApplyCouponCardHorizontalDialogComponent implements OnInit {
  @ViewChildren('pickCouponCallbackUrl') pickCouponCallbackUrl: QueryList<ElementRef>;
  @ViewChildren("couponCard") couponCards: QueryList<ElementRef>;
  @Input() prefixId: string = '';
  @Input() coupons: IApplyCouponDetail;
  @Input() couponChannel: string;
  @Input() entryPoint: string;
  @Input() applyCouponKeyword: string;
  @Input() stepRouteConfig: string[] = [];
  @Input() userSOF: string[] = [];
  @Output() onActionPickCoupon: EventEmitter<any> = new EventEmitter<any>();
  @Output() onActionUseCouponPaymentTopup: EventEmitter<any> = new EventEmitter<any>();
  @Output() onActionUseCouponExternalURL: EventEmitter<any> = new EventEmitter<any>();
  @Output() onActionUseCoupon: EventEmitter<any> = new EventEmitter<any>();
  @Output() onActionSetStepRoute: EventEmitter<any> = new EventEmitter<any>();
  @Output() emitOpenDialogButtom: EventEmitter<any> = new EventEmitter<any>();
  @Output() onActionNavigateToCouponDetail: EventEmitter<any> = new EventEmitter<any>();
  @Output() onActionTrackGAUseCoupon: EventEmitter<any> = new EventEmitter<any>();
  @Output() onActionSetToCloseDialog: EventEmitter<any> = new EventEmitter<any>();

  CouponCardTemplate = CouponCardTemplate;
  CouponStatus = CouponStatus;
  PeriodStatus = PeriodStatus;
  BadgeLabel = BadgeLabel;
  CouponMethod = CouponMethod;
  CopayNavigationType = CopayNavigationType;
  InternalNavigationType = InternalNavigationType;
  chooseCouponCallbackUrl = this.rootStoreService.jwtSession!.chooseCouponCallbackUrl;
  paymentCallbackUrl = this.rootStoreService.jwtSession!.paymentCallbackUrl;
  registerCallbackUrl = this.rootStoreService.jwtSession!.registerCallbackUrl ?? "mycoupon";
  processingCallbackUrl = this.rootStoreService.jwtSession!.processingCallbackUrl;
  PageRoute = PageRoute;
  DeductionType = DeductionType;
  sofList: string[] = [];

  constructor(
    private rootStoreService: RootStoreService,
    private couponService: CouponService,
    private modalService: ModalService,
    private changeDetectorRef: ChangeDetectorRef,
    private webviewToNativeInterfaceService: WebviewToNativeInterfaceService,
  ) { }

  ngOnInit(): void {

  }

  navigateToCouponDetail(coupon: IApplyCouponDetail, index: number = 0) {
    const selectCoupon = {
      couponId: coupon.couponId,
      numberOfCoupons: coupon.numberOfCoupons,
      couponInfos: [coupon.couponInfos[index]] ?? [] as ICouponInfo[]
    };
    this.onActionNavigateToCouponDetail.emit(selectCoupon);
  }


  useCoupon(coupon: IApplyCouponDetail, couponOrder: number, index: number) {
    const cardEl = this.getCouponCard(index);
    this.disableActionCard(cardEl, true);
    const selectCoupon = {
      couponId: coupon.couponId,
      campaignCode: coupon.campaignCode,
      hasCountdownTimer: coupon.hasCountdownTimer,
      couponInfos: [coupon.couponInfos[index]] ?? [] as ICouponInfo[]
    } as IApplyCouponDetail;
    this.onActionUseCouponForBottomDialogContent(cardEl, selectCoupon, couponOrder);
  }

  usePaymentTopup(coupon: IApplyCouponDetail, couponOrder: number, pageRoute: PageRoute, index: number, mode?: string) {
    const cardEl = this.getCouponCard(index);
    this.disableActionCard(cardEl, true);
    this.onActionTrackGAUseCoupon.emit();

    this.couponService.loadPermissionUseCoupon(coupon.couponId, couponOrder, true);
    this.couponService.permissionUseCoupon$.pipe(filter((v) => !isNil(v.permissionUseCoupon)), take(1))
      .subscribe((response) => {
        if (!response?.permissionUseCoupon) {
          this.displayPermissionError(coupon.campaignCode, response, true);
          return;
        }
        this.couponService.loadReserveCouponOrder(coupon.couponId, couponOrder, mode);
        this.couponService.clearPermissionUseCoupon();
      });

    this.couponService.reserveCouponOrder$.pipe(filter((v) => Boolean(v.result)), take(1))
      .subscribe((response) => {
        switch (response.result.code) {
          case ResponseCode.ERROR_NOT_REGISTERED:
          case ResponseCode.ERROR_REGISTERING: {
            const unRegisteredCallbackUrl = this.registerCallbackUrl?.replace('${campaignId}', coupon.couponId) + '&callbackMode=H' || "";
            const pendingRegisteredCallbackUrl = this.processingCallbackUrl?.replace('${campaignId}', coupon.couponId) || "";
            const firebaseParams: { [key: string]: FirebaseParam[] } = {
              [ResponseCode.ERROR_NOT_REGISTERED]: [
                {
                  key: AnalyticFirebaseParam.CAMPAIGN_ID,
                  value: coupon.campaignCode
                }
              ],
              [ResponseCode.ERROR_REGISTERING]: [
                {
                  key: AnalyticFirebaseParam.CAMPAIGN_ID,
                  value: coupon.campaignCode,
                }
              ]
            };

            const modalObj = checkRegisterStatus(response.result.code, unRegisteredCallbackUrl, pendingRegisteredCallbackUrl, firebaseParams);

            this.modalService.openModal(ModalTemplateComponent, { data: modalObj }).pipe(take(1)).subscribe(() => {
              this.disableActionCard(cardEl, false);
              this.couponService.clearReserveCouponOrder();
            });
            break;
          }

          case ResponseCode.ERROR_NOT_ELIGIBLE: {
            const firebaseParams: { [key: string]: FirebaseParam[] } = {
              [ResponseCode.ERROR_NOT_ELIGIBLE]: [
                {
                  key: AnalyticFirebaseParam.CAMPAIGN_ID,
                  value: coupon.campaignCode
                }
              ],
            };
            const modalObj = handleErrorCouponStatus(response.result.code, firebaseParams);
            this.modalService.openModal(ModalTemplateComponent, { data: modalObj }).pipe(take(1)).subscribe(() => {
              this.disableActionCard(cardEl, false);
              this.changeDetectorRef.detectChanges();
              this.couponService.clearReserveCouponOrder();
              this.setToCloseDialog(pageRoute)
            });
            break;
          }

          case ResponseCode.SUCCESS: {
            // Support PT Old version !! Remove next phase
            const unRegisteredCallbackUrl = this.registerCallbackUrl?.replace('${campaignId}', coupon.couponId) + '&callbackMode=H' || "";
            // check sof for navigate to register
            if (response.data.isNavigate) {
              const firebaseParams: FirebaseParam[] = [
                {
                  key: AnalyticFirebaseParam.CAMPAIGN_ID,
                  value: coupon.campaignCode
                }
              ];
              const modalObj = checkRegisterStatusAndType(response.data.navigationData, unRegisteredCallbackUrl, firebaseParams);
              this.modalService.openModal(ModalTemplateComponent, { data: modalObj }).pipe(take(1)).subscribe((result) => {
                if (result) {
                  this.webviewToNativeInterfaceService.interfaceToNative(
                    {
                      command: interfaceToNativeCommand.COMMAND_ROUTING,
                      content: {
                        destination: result
                      }
                    }
                  );
                }
              });
              this.couponService.clearReserveCouponOrder();
              this.disableActionCard(cardEl, false);
              return;
            }

            const selectedCoupon = {
              couponId: coupon.couponId,
              hasCountdownTimer: coupon.hasCountdownTimer,
              couponInfos: coupon.couponInfos.filter((couponInfo: ICouponInfo) => couponInfo.receivedOrder === couponOrder) ?? []
            }

            this.saveSelectedCoupon(selectedCoupon, pageRoute);
            this.couponService.clearReserveCouponOrder();
            this.disableActionCard(cardEl, false);
            //check destination
            if ([InternalNavigationType.PT_PAYMENT, InternalNavigationType.PT_TOPUP].includes(coupon.copayNavigation.destination as InternalNavigationType)) {

              this.webviewToNativeInterfaceService.interfaceToNative(
                {
                  command: interfaceToNativeCommand.COMMAND_ROUTING,
                  content: {
                    destination: coupon.copayNavigation.destination,
                    payeeCode: coupon.copayNavigation.compCode,
                    campaignId: coupon.couponId,
                    channel: "PT",
                    callbackMode: "A",
                    additionalParams: `${AdditionalParams.APPLY_KEYWORD}=${this.applyCouponKeyword}`
                  }
                }
              );
            }
            break;
          }

          default: {
            const modalObj: IModalTemplate = {
              title: modalWording.outOfService,
              detail: modalWording.pleaseTryAgainLater,
              analyticModal: {
                eventCategory: AnalyticCategory.COUPON_API_ERROR,
                firebaseParam: [
                  {
                    key: AnalyticFirebaseParam.CAMPAIGN_ID,
                    value: coupon.campaignCode!
                  }
                ]
              },
              button: [{ name: modalWording.ok, action: "", eventLabel: AnalyticLabel.OK }],
              deeplinkCallbackUrl: undefined
            };

            this.modalService.openModal(ModalTemplateComponent, { data: modalObj }).pipe(take(1)).subscribe(() => {
              this.couponService.clearReserveCouponOrder();
              this.disableActionCard(cardEl, false);
            });
            break;
          }
        }
      });
  }

  navigateExternalURL(coupon: IApplyCouponDetail, couponOrder: number, index: number) {
    const cardEl = this.getCouponCard(index);
    const selectCoupon = {
      element: cardEl,
      coupon: {
        couponId: coupon.couponId,
        campaignCode: coupon.campaignCode,
        couponInfos: [coupon.couponInfos[index]] ?? [] as ICouponInfo[]
      },
      externalUrl: coupon.copayNavigation.url
    }
    this.onActionTrackGAUseCoupon.emit();
    this.onActionUseCouponExternalURL.emit(selectCoupon)
  }

  useScanCopayCoupon(coupon: IApplyCouponDetail, couponOrder: number, pageRoute: PageRoute, elementId: string, index: number, mode?: string) {
    const cardEl = this.getCouponCard(index);
    this.disableActionCard(cardEl, true);
    this.onActionTrackGAUseCoupon.emit();

    this.couponService.loadPermissionUseCoupon(coupon.couponId, couponOrder);
    this.couponService.permissionUseCoupon$.pipe(filter((v) => !isNil(v.permissionUseCoupon)), take(1))
      .subscribe((response) => {
        if (!response.permissionUseCoupon) {
          this.displayPermissionError(coupon.campaignCode, response);
          return;
        }

        this.sofList = mappingUserSOFWithCoupon(this.userSOF, response.sof);
        this.couponService.loadReserveCouponOrder(coupon.couponId, couponOrder, mode);
        this.couponService.clearPermissionUseCoupon();
      });

    this.couponService.reserveCouponOrder$.pipe(filter((v) => Boolean(v.result)), take(1))
      .subscribe((response) => {

        switch (response.result.code) {
          case ResponseCode.ERROR_NOT_REGISTERED:
          case ResponseCode.ERROR_REGISTERING: {
            const unRegisteredCallbackUrl = this.registerCallbackUrl?.replace('${campaignId}', coupon.couponId) + '&callbackMode=H' || "";
            const pendingRegisteredCallbackUrl = this.processingCallbackUrl?.replace('${campaignId}', coupon.couponId) || "";
            const firebaseParams: { [key: string]: FirebaseParam[] } = {
              [ResponseCode.ERROR_NOT_REGISTERED]: [
                {
                  key: AnalyticFirebaseParam.CAMPAIGN_ID,
                  value: coupon.campaignCode
                }
              ],
              [ResponseCode.ERROR_REGISTERING]: [
                {
                  key: AnalyticFirebaseParam.CAMPAIGN_ID,
                  value: coupon.campaignCode
                }
              ]
            };
            const modalObj = checkRegisterStatus(response.result.code, unRegisteredCallbackUrl, pendingRegisteredCallbackUrl, firebaseParams);
            this.modalService.openModal(ModalTemplateComponent, { data: modalObj }).pipe(take(1)).subscribe(() => {
              this.disableActionCard(cardEl, false);
              this.couponService.clearReserveCouponOrder();
            });
            break;
          }

          case ResponseCode.ERROR_NOT_ELIGIBLE: {
            const firebaseParams: { [key: string]: FirebaseParam[] } = {
              [ResponseCode.ERROR_NOT_ELIGIBLE]: [
                {
                  key: AnalyticFirebaseParam.CAMPAIGN_ID,
                  value: coupon.campaignCode
                }
              ],
            };
            const modalObj = handleErrorCouponStatus(response.result.code, firebaseParams);
            this.modalService.openModal(ModalTemplateComponent, { data: modalObj }).pipe(take(1)).subscribe(() => {
              this.couponService.clearReserveCouponOrder();
              this.disableActionCard(cardEl, false);
              this.setToCloseDialog(pageRoute)
            });
            break;
          }

          case ResponseCode.SUCCESS: {
            // Support PT Old version !! Remove next phase
            const unRegisteredCallbackUrl = this.registerCallbackUrl?.replace('${campaignId}', coupon.couponId) + '&callbackMode=H' || "";
            // check sof for navigate to register
            if (response.data.isNavigate) {
              const firebaseParams: FirebaseParam[] = [
                {
                  key: AnalyticFirebaseParam.CAMPAIGN_ID,
                  value: coupon.campaignCode
                }
              ];
              const modalObj = checkRegisterStatusAndType(response.data.navigationData, unRegisteredCallbackUrl, firebaseParams);
              this.modalService.openModal(ModalTemplateComponent, { data: modalObj }).pipe(take(1)).subscribe((result) => {
                if (result) {
                  this.webviewToNativeInterfaceService.interfaceToNative(
                    {
                      command: interfaceToNativeCommand.COMMAND_ROUTING,
                      content: {
                        destination: result
                      }
                    }
                  );
                }
              });
              this.couponService.clearReserveCouponOrder();
              this.disableActionCard(cardEl, false);
              return;
            }

            const selectCoupon = {
              couponId: coupon.couponId,
              hasCountdownTimer: coupon.hasCountdownTimer,
              couponInfos: coupon.couponInfos.filter((couponInfo: ICouponInfo) => couponInfo.receivedOrder === couponOrder) ?? []
            }
            this.saveSelectedCoupon(selectCoupon, pageRoute);
            this.couponService.clearReserveCouponOrder();
            if (mode === 'ScanCoPay' && !this.stepRouteConfig.includes(PageRoute.PATH_COUPON_LIST)) {
              this.webviewToNativeInterfaceService.interfaceToNative(
                {
                  command: interfaceToNativeCommand.COMMAND_ROUTING,
                  content: {
                    destination: interfaceToNativeContentDestination.DESTINATION_SCAN_COPAY,
                    sof: this.sofList,
                    campaignId: coupon.couponId,
                    callbackMode: "A",
                    additionalParams: `${AdditionalParams.APPLY_KEYWORD}=${this.applyCouponKeyword}`
                  }
                }
              );
              // Support PT Old version !! Remove next phase
              if (this.pickCouponCallbackUrl) {
                const allCoupons = this.pickCouponCallbackUrl.toArray();
                const pickCoupon = allCoupons.find((pickCoupon) => pickCoupon.nativeElement.id === elementId);
                pickCoupon!.nativeElement.click();
              }
            } else {
              if (this.pickCouponCallbackUrl) {
                const allCoupons = this.pickCouponCallbackUrl.toArray();
                const pickCoupon = allCoupons.find((pickCoupon) => pickCoupon.nativeElement.id === elementId);
                pickCoupon!.nativeElement.click();
              }
            }
            this.disableActionCard(cardEl, false);
            break;
          }

          default: {
            const modalObj: IModalTemplate = {
              title: modalWording.outOfService,
              detail: modalWording.pleaseTryAgainLater,
              analyticModal: {
                eventCategory: AnalyticCategory.COUPON_API_ERROR,
                firebaseParam: [
                  {
                    key: AnalyticFirebaseParam.CAMPAIGN_ID,
                    value: coupon.campaignCode
                  }
                ]
              },
              button: [{ name: modalWording.ok, action: "", eventLabel: AnalyticLabel.OK }],
              deeplinkCallbackUrl: undefined
            };

            this.modalService.openModal(ModalTemplateComponent, { data: modalObj }).pipe(take(1)).subscribe(() => {
              this.couponService.clearReserveCouponOrder();
              this.disableActionCard(cardEl, false);
            });
            break;
          }
        }
      });
  }
  setStepRouteFlag(event: any) {
    this.onActionSetStepRoute.emit(event);
  }

  setToCloseDialog(pageRoute: PageRoute) {
    this.onActionSetToCloseDialog.emit(pageRoute)
  }

  convertDateFormat(val: any) {
    const date = new Date(val)
    return convertToLocaleDateString(date);
  }

  convertTimeFormat(val: any) {
    const date = new Date(val)
    return convertToLocaleTimeString(date);
  }

  isInChannel(coupon: any) {
    if (coupon.copayChannels && coupon.couponUseMethod == CouponMethod.COPAY) {
      if (coupon.copayChannels[0] === CouponChannel.PT && !coupon.copayChannels.includes(this.couponChannel || ''))
        return false

      // Scan QR only use on PT
      if (coupon.copayChannels[0] === CouponChannel.PT && coupon.isUseButtonDisplay && (this.entryPoint === CouponEntryPoint.PWP || this.couponChannel !== CouponChannel.PT)) {
        return false
      }

      if (coupon.copayChannels[0] === CouponChannel.PW && (!coupon.isUseButtonDisplay && !coupon.isCoPayNavigate) && this.entryPoint !== CouponEntryPoint.PWP)
        return false;

      if (!this.stepRouteConfig.includes(PageRoute.PATH_COUPON_LIST) && coupon.copayChannels[0] === CouponChannel.PW && (this.couponChannel !== CouponChannel.PT || (!coupon.isUseButtonDisplay && !coupon.isCoPayNavigate)))
        return false

      return true;
    }

    if (this.stepRouteConfig[this.stepRouteConfig.length - 2] === PageRoute.PATH_COUPON_LIST && coupon.couponUseMethod !== CouponMethod.COPAY) {
      return false;
    }

    return true
  }

  isDisplayBadgeLabel(status: any) {
    const arrStatus = [CouponStatus.REDEEM_CONFIRMED, CouponStatus.EXPIRED, CouponStatus.OUT_OF_QUOTA, CouponStatus.TEMPO_OUT_OF_QUOTA, CouponStatus.OUT_OF_USED_QUOTA, CouponStatus.NOT_ELIGIBLE_TO_USE];
    return !arrStatus.includes(status);
  }

  isCouponApplyCodeQRONCouponList(coupon: any) {
    return this.stepRouteConfig[this.stepRouteConfig.length - 2] === PageRoute.PATH_COUPON_LIST && coupon.couponUseMethod !== CouponMethod.COPAY
  }

  calculateTimeDiff(dateSent: any) {
    const newDate = new Date(dateSent);
    return calculateTimeDiff(newDate);
  }

  onActionUseCouponForBottomDialogContent(element: ElementRef<any>, coupon: IApplyCouponDetail, couponOrder: number, index: number = 0) {

    this.onActionTrackGAUseCoupon.emit();

    const selectedCoupon = {
      coupon: coupon,
      actionType: ''
    };

    const couponInfo = coupon.couponInfos;
    const dateRedeemdiff = this.calculateTimeDiff(couponInfo[index].couponEndRedemptionDateTime);
    const isCouponRedeemStatus = couponInfo[index].status === CouponStatus.REDEEMED;

    if (dateRedeemdiff < 0 && couponInfo[index].status !== CouponStatus.REDEEMED) {
      selectedCoupon.actionType = UseActionTypeModalTemplate.EXPIRED;
      this.onActionUseCoupon.emit(selectedCoupon);
    } else {
      // Success case
      if (isCouponRedeemStatus) {
        selectedCoupon.actionType = UseActionTypeModalTemplate.REDEEMED;
        this.onActionUseCoupon.emit(selectedCoupon);
      } else {
        this.couponService.loadPermissionUseCoupon(coupon.couponId, couponOrder);
        this.couponService.permissionUseCoupon$
          .pipe(filter((v) => !isNil(v.permissionUseCoupon)), take(1))
          .subscribe((response) => {
            if (!response.permissionUseCoupon) {
              this.displayPermissionError(coupon.campaignCode, response);
              return;
            }

            let txtDetail = "";
            if (coupon.hasCountdownTimer && couponInfo[index].countdownTimeInSeconds! > 0) {
              txtDetail = modalWording.afterConfirmCouponHaveTime + (couponInfo[index].countdownTimeInSeconds!) / 60 + modalWording.minute;
            }

            const modalObj: IModalTemplate = {
              title: modalWording.confirmUseCoupon,
              detail: txtDetail,
              analyticModal: {
                eventCategory: AnalyticCategory.COUPON_CONFIRM_USING,
                firebaseParam: [
                  {
                    key: AnalyticFirebaseParam.CAMPAIGN_ID,
                    value: coupon.campaignCode
                  }
                ]
              },
              button: [
                { name: modalWording.cancel, action: "", eventLabel: AnalyticLabel.CANCEL },
                { name: modalWording.confirm, action: "confirm", eventLabel: AnalyticLabel.CONFIRM }
              ]
            }
            this.modalService.openModal(ModalTemplateComponent, { data: modalObj }).pipe(take(1)).subscribe((result) => {
              this.disableActionCard(element, false);

              if (result == 'confirm') {
                selectedCoupon.actionType = UseActionTypeModalTemplate.SUCCESS;
                this.onActionUseCoupon.emit(selectedCoupon);
                this.couponService.clearPermissionUseCoupon();
              }
            });
          });
      }
    }
  }

  isDisplayProgressBarLabel(status: any) {
    const arrStatus = [CouponStatus.REDEEM_CONFIRMED, CouponStatus.EXPIRED, CouponStatus.OUT_OF_QUOTA, CouponStatus.TEMPO_OUT_OF_QUOTA, CouponStatus.OUT_OF_USED_QUOTA, CouponStatus.NOT_ELIGIBLE_TO_USE];
    return !arrStatus.includes(status);
  }

  isDisplayButton(status: string) {
    return status !== CouponStatus.REDEEM_CONFIRMED && status !== CouponStatus.EXPIRED && status !== CouponStatus.OUT_OF_QUOTA && status !== CouponStatus.TEMPO_OUT_OF_QUOTA && status !== CouponStatus.OUT_OF_USED_QUOTA && status !== CouponStatus.NOT_ELIGIBLE_TO_USE;
  }

  isDisplayDisableCard(status: string, periodStatus: string, isAboveMinimumSpend: boolean) {
    return status === CouponStatus.OUT_OF_QUOTA || status === CouponStatus.TEMPO_OUT_OF_QUOTA || status === CouponStatus.OUT_OF_USED_QUOTA || (status === CouponStatus.COLLECTED && (periodStatus === PeriodStatus.DATE || periodStatus === PeriodStatus.PERIOD || !isAboveMinimumSpend)) || (status === CouponStatus.NEW && (periodStatus === PeriodStatus.DATE || periodStatus === PeriodStatus.PERIOD));
  }

  mappingSelectedCoupon(coupon: IApplyCouponDetail, index = 0) {
    const selectedCoupon: SelectedCoupon = {
      couponId: coupon.couponId,
      couponCode: coupon.couponInfos[index].couponCode ?? '',
      hasCountdownTimer: coupon.hasCountdownTimer ?? false,
      countdownTimeInSeconds: coupon.couponInfos[index].countdownTimeInSeconds ?? 0,
      order: coupon.couponInfos[index].receivedOrder,
      couponStatus: coupon.couponInfos[index].status
    };
    return selectedCoupon;
  }

  private saveSelectedCoupon(ev: any, pathRoute: PageRoute) {
    const selectedCoupon = this.mappingSelectedCoupon(ev);
    this.couponService.saveSelectedCouponValue(selectedCoupon, pathRoute);
  }

  private displayPermissionError(campaignCode: string, permisionCoupon: IPermissionUseCouponResponse, isNavigate: boolean = false) {
    if (isNavigate && permisionCoupon?.status && (permisionCoupon?.status === CouponStatus.OUT_OF_QUOTA || permisionCoupon?.status === CouponStatus.OUT_OF_USED_QUOTA || permisionCoupon?.status === CouponStatus.TEMPO_OUT_OF_QUOTA)) {
      const firebaseParams: { [key: string]: FirebaseParam[] } = {
        [ResponseCode.ERROR_OUT_OF_QUOTA]: [
          {
            key: AnalyticFirebaseParam.CAMPAIGN_ID,
            value: campaignCode
          }
        ],
      };
      const modalObj = handleErrorCouponStatus(ResponseCode.ERROR_OUT_OF_QUOTA, firebaseParams);
      this.modalService.openModal(ModalTemplateComponent, { data: modalObj }).pipe(take(1)).subscribe((result) => {
        if (result == 'confirm') {
          this.changeDetectorRef.detectChanges();
          this.couponService.clearPermissionUseCoupon();
          this.couponService.loadFetchApplyCoupon(this.applyCouponKeyword);
        }
      });
    } else {
      if (permisionCoupon.result?.code == ResponseCode.ERROR_COUPON_DETAIL_ELIGIBLE_NOTFOUND) {
        const firebaseParams: { [key: string]: FirebaseParam[] } = {
          [ResponseCode.ERROR_COUPON_DETAIL_ELIGIBLE_NOTFOUND]: [
            {
              key: AnalyticFirebaseParam.CAMPAIGN_ID,
              value: campaignCode
            }
          ],
        };
        const modalObj: IModalTemplate = handleDisplayPermissionUse(ResponseCode.ERROR_COUPON_DETAIL_ELIGIBLE_NOTFOUND, CouponActionType.USE, firebaseParams);
        this.modalService.openModal(ModalTemplateComponent, { data: modalObj }).pipe(take(1)).subscribe((result) => {
          if (result == "confirm") {
            this.changeDetectorRef.detectChanges();
            this.couponService.clearPermissionUseCoupon();
            this.couponService.loadFetchApplyCoupon(this.applyCouponKeyword);
          }
        });
      } else {
        const firebaseParams: { [key: string]: FirebaseParam[] } = {
          [ResponseCode.ERROR_API]: [
            {
              key: AnalyticFirebaseParam.CAMPAIGN_ID,
              value: campaignCode
            }
          ],
        };
        const modalObj: IModalTemplate = handleDisplayPermissionUse(ResponseCode.ERROR_API, CouponActionType.USE, firebaseParams);
        this.modalService.openModal(ModalTemplateComponent, { data: modalObj }).pipe(take(1)).subscribe((result) => {
          if (result == "confirm") {
            this.changeDetectorRef.detectChanges();
            this.couponService.clearPermissionUseCoupon();
            this.couponService.loadFetchApplyCoupon(this.applyCouponKeyword);
          }
        });
      }
    }
  }

  getCouponCard(index: number) {
    return this.couponCards.toArray()[index];
  }

  disableActionCard(element: ElementRef<any>, value: boolean = false) {
    if (value) {
      element.nativeElement.classList.add('disabled-click-action')
    } else {
      element.nativeElement.classList.remove('disabled-click-action')
    }
  }
}
