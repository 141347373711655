import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild, SecurityContext } from '@angular/core';
import { Router } from '@angular/router';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { ModalTemplateComponent } from '../../components/modal-template/modal-template.component';
import { UtilService } from '../../service/util.service';
import { CouponService } from '../../../shared/coupon.service';
import { filter, first, Subject, take, takeUntil, zip } from 'rxjs';
import { ICouponDetail, IPermissionUseCouponResponse } from '../../model/coupon-model.model';
import { CountdownEvent } from 'ngx-countdown';
import { RootStoreService } from '../../root-store/root-store.service';
import { ModalService } from '../../service/modal.service';
import { SelectedCoupon } from '../../root-store/root-store.model';
import { cloneDeep, isEmpty, isNil } from 'lodash';
import { PageRoute } from '../../configs/route-config';
import { IModalTemplate } from '../../model/coupon-modal.model';
import { modalWording, Mode, ResponseCode, ResponseMessage } from '../../configs/app-config';
import { getPeriodTime } from '../../helpers/period-time.helper';
import { calculateTimeDiff, convertToLocaleDateString, convertToLocaleTimeString } from '../../helpers/date-time.helper';
import { DomSanitizer, Title } from "@angular/platform-browser";
import { CouponGAService } from '../../service/coupon-ga.service';
import { CouponEventTagAction } from '../../model/coupon-ga.model';
import { AdditionalParams, CopayNavigationType, CouponActionType, CouponChannel, CouponEntryPoint, CouponMethod, CouponStatus, CouponType, DeductionType, InternalNavigationType, PeriodStatus, sourceOfFundsType } from '../../model/coupon-enum.model';
import { checkRegisterStatus, checkRegisterStatusAndType, handleDisplayPermissionUse, handleErrorCouponStatus, mappingUserSOFWithCoupon } from '../../helpers/coupon.helper';
import { WebviewToNativeInterfaceService } from '../../webview-to-native-interface/webview-to-native-interface.service';
import { AnalyticAction, AnalyticCategory, AnalyticFirebaseParam, AnalyticLabel, interfaceToNativeCommand, interfaceToNativeContentDestination } from '../../webview-to-native-interface/webview-to-native-interface-enum.model';
import { FirebaseParam } from '../../webview-to-native-interface/webview-to-native-interface.model';

declare let window: any;

@Component({
  selector: 'app-coupon-detail',
  templateUrl: './coupon-detail.component.html',
  styleUrls: ['./coupon-detail.component.scss'],
})

export class CouponDetailComponent implements OnInit {
  isEmpty = isEmpty
  @ViewChild('toastPopup') toastPopup: any;
  @ViewChild('navigateCoPayPaymentUrl') navigateCoPayPaymentUrl: ElementRef<any>
  faChevronLeft = faChevronLeft;
  couponId$ = this.couponService.couponId$;
  previousUrl$ = this.couponService.previousUrl$;
  isAndroid: boolean = this.utilService.isMobileDevice();
  couponDetail: ICouponDetail | undefined;
  couponId: string = '';
  status: string = this.rootStoreService.jwtSession!.status ?? "ACTIVE";
  mode: string = this.rootStoreService.jwtSession!.mode;
  callbackUrl$ = this.couponService.callbackUrl$;
  registerCallbackUrl = this.rootStoreService.jwtSession!.registerCallbackUrl ?? "mycoupon";
  processingCallbackUrl = this.rootStoreService.jwtSession!.processingCallbackUrl;
  callbackUrl = this.rootStoreService.jwtSession!.callbackUrl;
  chooseCouponCallbackUrl = this.rootStoreService.jwtSession!.chooseCouponCallbackUrl;
  paymentCallbackUrl = this.rootStoreService.jwtSession!.paymentCallbackUrl;
  couponChannel = this.rootStoreService.jwtSession?.couponChannel;
  entryPoint: string = this.rootStoreService.jwtSession!.entryPoint;
  sourceOfFund = this.rootStoreService.jwtSession?.sourceOfFund;
  callbackDestination = this.rootStoreService.jwtSession!.callbackDestination;
  redeemedDate: string = "";
  isEndTimer: boolean = false;
  withHeader: boolean = true;
  backCallbackUrl: string = "";
  cancelMode: boolean = false;
  hasCountdownTimer: boolean = true;
  showTimerBox: boolean = false;
  isPreviousPage: boolean = true;
  stepRouteConfig$ = this.couponService.stepRouteConfig$;
  isLoading$ = this.couponService.isLoading$;
  selectedCoupon$ = this.couponService.selectedCoupon$;
  stepRouteConfig: string[] = [];
  coPayPaymentUrl: string = "";
  unRegisteredCallbackUrl: string = '';
  pendingRegisteredCallbackUrl: string = '';
  chooseCouponCallbackSafeUrl: string = '';
  cancelCallbackUrl: string = '';
  isInChannels: boolean = true;
  barColor: string = "bar-gray";
  clickedCollect: boolean = false;
  clickedRedeem: boolean = false;
  CouponStatus = CouponStatus;
  PageRoute = PageRoute;
  CouponChannel = CouponChannel;
  CouponEntryPoint = CouponEntryPoint;
  CouponMethod = CouponMethod;
  DeductionType = DeductionType;
  PeriodStatus = PeriodStatus;
  SourceOfFundsType = sourceOfFundsType;
  CopayNavigationType = CopayNavigationType;
  InternalNavigationType = InternalNavigationType;
  periodTimeCount: number = 0;
  window: any;
  CouponType = CouponType;
  sofList: string[] = [];
  keywordParams: string = "";
  categorySelected: string = "";
  userSOF: string[] = [];

  private _destroy = new Subject<void>();

  constructor(
    private router: Router,
    private sanitizer: DomSanitizer,
    private couponService: CouponService,
    private utilService: UtilService,
    private rootStoreService: RootStoreService,
    private modalService: ModalService,
    private changeDetectorRef: ChangeDetectorRef,
    private couponGAService: CouponGAService,
    private titleService: Title,
    private webviewToNativeInterfaceService: WebviewToNativeInterfaceService,
  ) {
    this.utilService?.startLoading(PageRoute.COUPON_DETAIL);
    this.window = window;
    this.titleService.setTitle("CouponDetails");
  }

  ngOnInit(): void {
    this.couponId$.pipe(filter((v) => Boolean(v)), takeUntil(this._destroy)).subscribe((couponId) => this.couponId = couponId);
    this.isAndroid = this.utilService?.isMobileDevice();
    this.couponService.saveStepRouteConfig([PageRoute.PATH_COUPON_DETAIL]);
    this.couponService.saveCurrentPage(`/coupon-detail?couponId=${this.couponId}`);

    this.rootStoreService.jwtSession$.pipe(takeUntil(this._destroy)).subscribe((jwtSession) => {
      this.callbackUrl = jwtSession.callbackUrl;
      this.registerCallbackUrl = jwtSession.registerCallbackUrl;
      this.chooseCouponCallbackUrl = jwtSession.chooseCouponCallbackUrl;
      this.processingCallbackUrl = jwtSession.processingCallbackUrl;
      this.paymentCallbackUrl = jwtSession.paymentCallbackUrl;
      this.couponChannel = jwtSession.couponChannel;
      this.entryPoint = jwtSession.entryPoint;
      this.withHeader = jwtSession.withHeader ?? true;
      this.sourceOfFund = jwtSession.sourceOfFund;
      this.mode = jwtSession.mode;
      this.callbackDestination = jwtSession.callbackDestination;
      this.userSOF = jwtSession.userSOF ?? [];

      // Cancel mode
      this.cancelMode = this.mode === Mode.CANCEL;
      this.coPayPaymentUrl = this.paymentCallbackUrl?.replace('${campaignId}', this.couponId) + '&callbackMode=D' || "";
      this.unRegisteredCallbackUrl = this.registerCallbackUrl?.replace('${campaignId}', this.couponId) + '&callbackMode=D' || "";
      this.pendingRegisteredCallbackUrl = this.processingCallbackUrl?.replace('${campaignId}', this.couponId) || "";
      this.chooseCouponCallbackSafeUrl = this.chooseCouponCallbackUrl?.replace('${campaignId}', this.couponId) || "";
    })

    this.couponService.isInitialised$.pipe(first((v) => v)).subscribe((v) => {
      this.couponService.loadFetchCouponDetail(true);
      this.changeDetectorRef.detectChanges();
    });

    this.couponService.couponDetail$.pipe(filter((v) => Boolean(v.data.coupon)), takeUntil(this._destroy))
      .subscribe((response) => {
        this.clickedCollect = false;
        this.loadAnimationCollectButton();
        if (response.data.coupon) {
          this.couponDetail = cloneDeep(response.data.coupon);
          this.couponDetail.description = this.processTextLinkT(this.couponDetail.description);
          this.couponDetail.termsAndCondition = this.processTextLinkT(this.couponDetail.termsAndCondition);
          this.couponDetail.campaignSOFList = this.couponDetail.campaignSOFList ?? [];
          this.couponDetail.couponType = this.couponDetail.couponType ?? "";

          //check only status is redeem confirm (used) because when use coupon unsuccessful must go back.
          if (sessionStorage.getItem("checkCouponDetailStatus") && this.couponDetail!.status == CouponStatus.REDEEM_CONFIRMED) {
            this.couponService.clearStepRouteConfig();
            this.couponService.saveStepRouteConfig([PageRoute.PATH_COUPON_DETAIL]);
            this.stepRouteConfig = [PageRoute.PATH_COUPON_DETAIL];
          }

          sessionStorage.removeItem("checkCouponDetailStatus");

          if (this.couponDetail!.couponQuotaLeft === 0 && this.couponDetail.status === CouponStatus.UNCOLLECTED)
            this.couponDetail.status = CouponStatus.OUT_OF_QUOTA;

          if (this.couponDetail.copayChannels && this.couponDetail.status != CouponStatus.UNCOLLECTED && this.couponDetail.couponUseMethod == CouponMethod.COPAY) {
            if (this.couponDetail.copayChannels[0] === CouponChannel.PT) {
              if (!this.couponDetail.copayChannels.includes(this.couponChannel || '')) {
                this.isInChannels = false;
              }

              // Scan QR only use on PT
              if (this.couponDetail.isUseButtonDisplay && (this.entryPoint === CouponEntryPoint.PWP || this.couponChannel !== CouponChannel.PT)) {
                this.isInChannels = false;
              }
            } else if (this.couponDetail.copayChannels[0] === CouponChannel.PW) {
              // No Navigate
              if (this.entryPoint !== CouponEntryPoint.PWP && (!this.couponDetail.isUseButtonDisplay && !this.couponDetail.isCoPayNavigate)) {
                this.isInChannels = false;
              }

              if (!this.stepRouteConfig.includes(PageRoute.PATH_COUPON_LIST) && !this.couponDetail.isUseButtonDisplay && !this.couponDetail.isCoPayNavigate && !this.cancelMode) {
                this.isInChannels = false;
              }

              // Navigate External
              if (this.couponDetail.isCoPayNavigate && this.couponChannel !== CouponChannel.PT) {
                this.isInChannels = false;
              }
            }
          }

          // Check Copay Channel

          const date = response.data.coupon.redeemedDateTime !== null && this.couponDetail.status === CouponStatus.REDEEM_CONFIRMED ? response.data.coupon.redeemedDateTime : response.data.coupon.redemptionEndDateTime;
          const redeemTime = new Date(response.data.coupon.redeemedDateTime)
          const showRedeemHour = (redeemTime.getHours() < 10 ? '0' : '') + redeemTime.getHours();
          const showRedeemMinute = (redeemTime.getMinutes() < 10 ? '0' : '') + redeemTime.getMinutes();
          this.redeemedDate = this.convertToLocaleDateString(date) + ((this.couponDetail.status == CouponStatus.NEW || this.couponDetail.status == CouponStatus.COLLECTED) ? "" : (" " + showRedeemHour + ":" + showRedeemMinute + " น."));

          // Is CoPay
          if (this.couponChannel == CouponChannel.PT && this.couponDetail.couponUseMethod == CouponMethod.COPAY && this.cancelMode && this.couponDetail.status != CouponStatus.UNCOLLECTED) {
            // (2) Cancel mode from other App.
            this.cancelCallbackUrl = this.chooseCouponCallbackUrl?.replace('?campaignId=${campaignId}', "");
          }

          // Display Timer
          if (response.data.coupon.couponUseMethod !== CouponMethod.COPAY && response.data.coupon.status === CouponStatus.REDEEMED && response.data.coupon.hasCountdownTimer && response.data.coupon.countdownTimeInSeconds > 0)
            this.showTimerBox = true;

          // Progress bar
          if (this.isDisplayProgressBarLabel()) {
            if (this.couponDetail.progressionPercent >= 1 && this.couponDetail.progressionPercent <= 75) {
              this.barColor = "bar-green";
            } else if (this.couponDetail.progressionPercent >= 76 && this.couponDetail.progressionPercent <= 99) {
              this.barColor = "bar-orange";
            }
          }


          if ((this.couponDetail!.periodStatus === PeriodStatus.ALLOWED || this.couponDetail!.periodStatus === PeriodStatus.PERIOD)) {
            this.handlePeriodTimer(this.couponDetail.status);
          }

          if (this.couponDetail!.status === CouponStatus.REDEEMED && this.couponDetail!.hasCountdownTimer === false) {
            this.handlePeriodTimer(this.couponDetail.status);
          }

          const convertCouponStatus = this.couponDetail!.status === CouponStatus.UNCOLLECTED ? CouponStatus.NEW : this.couponDetail!.status === CouponStatus.NEW ? CouponStatus.COLLECTED : this.couponDetail!.status;
          const selectedCoupon = this.mappingSelectedCoupon(this.couponDetail!.couponOrder, convertCouponStatus);
          this.couponService.saveSelectedCouponValue(selectedCoupon, PageRoute.PATH_COUPON_DETAIL);
          this.clickedRedeem = false;
          this.changeDetectorRef.detectChanges();
        }

      });

    this.stepRouteConfig$.pipe(filter((v) => v?.length > 0), take(1)).subscribe((route) => {
      this.stepRouteConfig = route;
    });

    this.couponService.selectedCategoryId$.pipe(filter(v => v !== '0'), take(1))
      .subscribe((categoryId) => {
        this.categorySelected = `${AdditionalParams.CATEGORY}=${categoryId}`;
      });

    this.couponService.searchKeyword$.pipe(filter(v => !isEmpty(v)), take(1))
      .subscribe((searchKeyword) => {
        this.keywordParams = `${AdditionalParams.SEARCH_KEYWORD}=${searchKeyword}`;
      });

    this.couponService.applyCouponKeyword$.pipe(filter(v => !isEmpty(v)), take(1))
      .subscribe((applyCouponKeyword) => {
        this.keywordParams = `${AdditionalParams.APPLY_KEYWORD}=${applyCouponKeyword}`;
      });

    this.couponService.isLoadingCollectedSccuess$
      .pipe(filter((v) => Boolean(v)), takeUntil(this._destroy))
      .subscribe((response) => {
        this.toastPopup.nativeElement.classList.remove("is-hidden");
        this.toastPopup.nativeElement.classList.add("fade-out-text");
        setTimeout(() => {
          this.toastPopup.nativeElement.classList.add("is-hidden");
          this.toastPopup.nativeElement.classList.remove("fade-out-text");
        }, 3000);
      });

    window.scrollTo(0, 0)
  }

  ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
    this.couponService.clearCouponDetail();
    this.couponService.isLoadingCollectedSccuess$.next(false);
  }

  isDisplayProgressBarLabel() {
    return !this.couponDetail!.isHiddenProgressBar && this.couponDetail?.status !== this.CouponStatus.REDEEMED && this.couponDetail?.status !== this.CouponStatus.REDEEM_CONFIRMED && this.couponDetail?.status !== this.CouponStatus.EXPIRED && this.couponDetail?.status !== this.CouponStatus.OUT_OF_QUOTA && this.couponDetail?.status !== this.CouponStatus.OUT_OF_USED_QUOTA && this.couponDetail?.status !== this.CouponStatus.TEMPO_OUT_OF_QUOTA && this.couponDetail!.progressionPercent < 100 && this.couponDetail!.isEligibleToUse && !this.isCouponApplyCodeQRONCouponList() && !(this.couponDetail!.couponType === CouponType.APPLYCODE && this.couponDetail!.status === CouponStatus.UNCOLLECTED);
  }

  isOpenDeepLinkCouponDetail() {
    return this.stepRouteConfig.length <= 1;
  }

  isCouponApplyCodeQRONCouponList() {
    return this.couponDetail!.couponType === CouponType.APPLYCODE && this.couponDetail!.couponUseMethod !== CouponMethod.COPAY && this.stepRouteConfig.includes(PageRoute.PATH_COUPON_LIST);
  }

  removeStepRouteConfig(isNavigate: boolean = false) {
    this.couponService.clearCouponDetail()
    if (isNavigate) {
      if (this.stepRouteConfig.length > 1) {
        const path = this.stepRouteConfig[this.stepRouteConfig.length - 2]
        this.couponService.removeStepRouteConfig(PageRoute.PATH_COUPON_DETAIL);
        this.router.navigate([path], { replaceUrl: true });
      }
    } else {
      this.couponService.removeStepRouteConfig(PageRoute.PATH_COUPON_DETAIL)
    }
  }

  convertToLocaleDateString(date: any) {
    return convertToLocaleDateString(new Date(date));
  }

  convertToLocaleTimeString(data: any) {
    return convertToLocaleTimeString(new Date(data));
  }

  setStepRouteFlag(isUseCoupon: boolean = false) {
    this.couponService.removeStepRouteConfig(PageRoute.PATH_COUPON_DETAIL);
    this.utilService.removeTokenSession();
    if (isUseCoupon) {
      if (this.stepRouteConfig.includes(PageRoute.PATH_APPLY_COUPON)) {
        this.couponService.removeStepRouteConfig(PageRoute.PATH_APPLY_COUPON);
      }
      this.couponService.removeStepRouteConfig(PageRoute.PATH_COUPON_LIST);
      if (localStorage.getItem("navigateExternalPageTemp")) {
        localStorage.setItem("navigateExternalPage", localStorage.getItem("navigateExternalPageTemp")!);
      }
    } else {
      localStorage.setItem("navigateExternalPage", this.couponId);
    }
  }

  navigateBackWithJsbridge() {
    this.webviewToNativeInterfaceService.interfaceToNative(
      {
        command: interfaceToNativeCommand.COMMAND_ROUTING,
        content: {
          destination: this.callbackDestination,
        }
      }
    );
  }

  pickCoupon(event: any) {
    this.setGATag(event, `${AnalyticCategory.COUPON_DETAILS}_Collect`);
    this.webviewToNativeInterfaceService.trackAction(
      {
        action: AnalyticAction.CLICK,
        category: AnalyticCategory.COUPON_DETAILS,
        label: `${AnalyticCategory.COUPON_DETAILS}_Collect`,
        firebase_screen: AnalyticCategory.COUPON_DETAILS,
      },
      this.couponChannel === CouponChannel.NEXT ?
        [
          {
            index: 4,
            key: AnalyticFirebaseParam.INFORMATION,
            value: this.couponDetail?.couponTitle!
          }
        ] : [
          {
            key: AnalyticFirebaseParam.CAMPAIGN_ID,
            value: this.couponDetail?.campaignCode!
          }
        ]
    );

    this.clickedCollect = true;
    this.loadAnimationCollectButton(true);
    this.couponService.loadPermissionUseCoupon(this.couponId, this.couponDetail!.couponOrder);
    this.couponService.permissionUseCoupon$.pipe(filter((v) => !isNil(v.permissionUseCoupon)), take(1))
      .subscribe((response) => {

        if (!response.permissionUseCoupon) {
          this.displayPermissionError(response, CouponActionType.COLLECT);
          return;
        }

        this.couponService.loadFetchCollectCoupon(this.couponId, this.couponDetail!.couponOrder);
        this.couponService.clearPermissionUseCoupon();
      });

    this.couponService.collectCoupon$
      .pipe(filter((v) => Boolean(v.result)), take(1))
      .subscribe((response) => {

        if (response.result.code == ResponseCode.ERROR_NOT_REGISTERED || response.result.code == ResponseCode.ERROR_REGISTERING) {
          const firebaseParams: { [key: string]: FirebaseParam[] } = {
            [ResponseCode.ERROR_NOT_REGISTERED]: [
              {
                key: AnalyticFirebaseParam.CAMPAIGN_ID,
                value: this.couponDetail!.campaignCode
              }
            ],
            [ResponseCode.ERROR_REGISTERING]: [
              {
                key: AnalyticFirebaseParam.CAMPAIGN_ID,
                value: this.couponDetail!.campaignCode,
              }
            ]
          };
          this.clickedCollect = false;
          this.loadAnimationCollectButton();
          const modalObj = checkRegisterStatus(response.result.code, this.unRegisteredCallbackUrl, this.pendingRegisteredCallbackUrl, firebaseParams);
          this.modalService.openModal(ModalTemplateComponent, { data: modalObj }).pipe(take(1)).subscribe(() => {
            this.setStepRouteFlag();
            this.couponService.clearCollectCoupon();
          });
          return;
        }

        if (response.result.code == ResponseCode.ERROR_OUT_OF_QUOTA || response.result.code == ResponseCode.ERROR_COUPON_EXPIRED || response.result.code == ResponseCode.ERROR_COUPON_NOTFOUND || response.result.code == ResponseCode.ERROR_NOT_ELIGIBLE) { // quota = 0 || expired || Notfound

          const firebaseParams: { [key: string]: FirebaseParam[] } = {
            [ResponseCode.ERROR_OUT_OF_QUOTA]: [
              {
                key: AnalyticFirebaseParam.CAMPAIGN_ID,
                value: this.couponDetail?.campaignCode!
              }
            ],
            [ResponseCode.ERROR_COUPON_EXPIRED]: [
              {
                key: AnalyticFirebaseParam.CAMPAIGN_ID,
                value: this.couponDetail?.campaignCode!,
              }
            ],
            [ResponseCode.ERROR_COUPON_NOTFOUND]: [
              {
                key: AnalyticFirebaseParam.CAMPAIGN_ID,
                value: this.couponDetail?.campaignCode!
              }
            ],
            [ResponseCode.ERROR_NOT_ELIGIBLE]: [
              {
                key: AnalyticFirebaseParam.CAMPAIGN_ID,
                value: this.couponDetail?.campaignCode!
              }
            ],
          };
          this.loadAnimationCollectButton();
          const modalObj = handleErrorCouponStatus(response.result.code, firebaseParams);
          this.modalService.openModal(ModalTemplateComponent, { data: modalObj }).pipe(take(1)).subscribe(() => {
            this.removeStepRouteConfig(true);
            this.couponService.clearCollectCoupon();
          });


          if (response.result.code == ResponseCode.ERROR_COUPON_EXPIRED) {
            this.loadAnimationCollectButton();
            this.couponDetail!.status = this.CouponStatus.EXPIRED;
            this.changeDetectorRef.detectChanges();
          }

        } else if (response.result.code == ResponseCode.SUCCESS) { // success
          const selectedCoupon = this.mappingSelectedCoupon(response.data.couponOrder!, CouponStatus.COLLECTED);
          this.couponService.saveSelectedCouponValue(selectedCoupon, PageRoute.PATH_COUPON_DETAIL);
          this.periodTimeCount = 0;
          this.changeDetectorRef.detectChanges();
          this.couponService.loadFetchCouponDetail(false, false, true);
          this.couponService.clearCollectCoupon();
        }
      });
  }

  private mappingSelectedCoupon(couponOrder: number, couponStatus: string) {
    const selectedCoupon: SelectedCoupon = {
      couponId: this.couponId,
      couponCode: this.couponDetail?.couponCode ?? '',
      hasCountdownTimer: this.couponDetail?.hasCountdownTimer ?? false,
      countdownTimeInSeconds: this.couponDetail?.countdownTimeInSeconds ?? 0,
      order: couponOrder,
      couponStatus: couponStatus
    };

    return selectedCoupon;

  }

  useCoupon(event: any, eventLabel: string) {
    this.setGATag(event, `${AnalyticCategory.COUPON_DETAILS}_${eventLabel}`);

    this.webviewToNativeInterfaceService.trackAction(
      {
        action: AnalyticAction.CLICK,
        category: AnalyticCategory.COUPON_DETAILS,
        label: `${AnalyticCategory.COUPON_DETAILS}_${eventLabel}`,
        firebase_screen: AnalyticCategory.COUPON_DETAILS,
      },
      this.couponChannel === CouponChannel.NEXT ?
        [
          {
            index: 4,
            key: AnalyticFirebaseParam.INFORMATION,
            value: this.couponDetail?.couponTitle!
          }
        ] :
        [
          {
            key: AnalyticFirebaseParam.CAMPAIGN_ID,
            value: this.couponDetail?.campaignCode!
          }
        ]
    );

    this.clickedRedeem = true;
    const dateRedeemdiff = this.calculateTimeDiff(this.couponDetail!.redemptionEndDateTime);
    if (dateRedeemdiff < 0 && this.couponDetail!.status !== CouponStatus.REDEEMED) {
      const modalObj: IModalTemplate = {
        title: modalWording.thisCouponExpired,
        detail: modalWording.chooseAnotherCoupon,
        analyticModal: {
          eventCategory: AnalyticCategory.COUPON_EXPIRED,
          firebaseParam: this.couponChannel === CouponChannel.NEXT ?
            [
              {
                index: 4,
                key: AnalyticFirebaseParam.INFORMATION,
                value: this.couponDetail?.couponTitle!
              }
            ] :
            [
              {
                key: AnalyticFirebaseParam.CAMPAIGN_ID,
                value: this.couponDetail?.campaignCode!
              }
            ]
        },
        button: [{ name: modalWording.viewAnotherCoupon, action: "", eventLabel: AnalyticLabel.SEE_OTHER }],
        deeplinkCallbackUrl: this.stepRouteConfig.length <= 1 ? this.callbackUrl : undefined
      };
      this.modalService.openModal(ModalTemplateComponent, { data: modalObj }).pipe(take(1)).subscribe(() => {
        this.removeStepRouteConfig(true);
      });
      this.couponDetail!.status = this.CouponStatus.EXPIRED;
      this.changeDetectorRef.detectChanges();
    } else {

      if (this.couponDetail!.status === CouponStatus.REDEEMED) {
        const selectedCoupon = this.mappingSelectedCoupon(this.couponDetail!.couponOrder, this.couponDetail!.status);
        this.couponService.saveSelectedCouponValue(selectedCoupon, PageRoute.PATH_COUPON_DETAIL);
        this.router.navigate([PageRoute.PATH_QR_CODE], { queryParams: selectedCoupon, replaceUrl: true });
      } else {

        this.couponService.loadPermissionUseCoupon(this.couponId, this.couponDetail!.couponOrder);
        this.couponService.permissionUseCoupon$
          .pipe(filter((v) => !isNil(v.permissionUseCoupon)), take(1))
          .subscribe((response) => {
            if (!response.permissionUseCoupon) {
              this.displayPermissionError(response, CouponActionType.USE);
              return
            }

            let txtDetail = "";
            if (this.couponDetail!.hasCountdownTimer && this.couponDetail!.countdownTimeInSeconds > 0) {
              let countdownTimer = (this.couponDetail!.countdownTimeInSeconds) / 60;
              txtDetail = modalWording.afterConfirmCouponHaveTime + countdownTimer + modalWording.minute;
            }

            const modalObj: IModalTemplate = {
              title: modalWording.confirmUseCoupon,
              detail: txtDetail,
              analyticModal: {
                eventCategory: AnalyticCategory.COUPON_CONFIRM_USING,
                firebaseParam: this.couponChannel === CouponChannel.NEXT ?
                  [
                    {
                      index: 4,
                      key: AnalyticFirebaseParam.INFORMATION,
                      value: `${this.couponDetail?.countdownTimeInSeconds}`
                    },
                    {
                      index: 4,
                      key: AnalyticFirebaseParam.INFORMATION,
                      value: this.couponDetail?.couponTitle!
                    }
                  ] :
                  [
                    {
                      key: AnalyticFirebaseParam.CAMPAIGN_ID,
                      value: this.couponDetail?.campaignCode!
                    }
                  ]
              },
              button: [
                { name: modalWording.cancel, action: "", eventLabel: AnalyticLabel.CANCEL },
                { name: modalWording.confirm, action: "confirm", eventLabel: AnalyticLabel.CONFIRM }
              ],
            }

            this.modalService.openModal(ModalTemplateComponent, { data: modalObj }).pipe(take(1)).subscribe((result) => {
              this.clickedRedeem = false;
              if (result == "confirm") {
                const selectedCoupon = this.mappingSelectedCoupon(this.couponDetail!.couponOrder, this.couponDetail!.status);
                this.couponService.saveSelectedCouponValue(selectedCoupon, PageRoute.PATH_COUPON_DETAIL);
                this.couponService.clearPermissionUseCoupon();
                this.router.navigate([PageRoute.PATH_QR_CODE], { queryParams: selectedCoupon, replaceUrl: true });
              }
            });
          });
      }
    }
  }

  calculateTimeDiff(dateSent: any) {
    const newDate = new Date(dateSent);
    return calculateTimeDiff(newDate);
  }

  handleEvent(e: CountdownEvent) {
    // Timeout
    if (e.action === 'done') {
      this.isEndTimer = true;
      let modalObj: IModalTemplate = {
        title: modalWording.thisCouponExpired,
        detail: modalWording.chooseAnotherCoupon,
        analyticModal: {
          eventCategory: AnalyticCategory.COUPON_EXPIRED,
          firebaseParam: [
            {
              key: AnalyticFirebaseParam.CAMPAIGN_ID,
              value: this.couponDetail?.campaignCode!
            }
          ]
        },
        button: [
          { name: modalWording.viewAnotherCoupon, action: "confirm", eventLabel: AnalyticLabel.CONFIRM }
        ]
      }
      this.modalService.openModal(ModalTemplateComponent, { data: modalObj }).pipe(take(1)).subscribe((result) => {
        if (result == 'confirm') {
          localStorage.removeItem('onCheckedTab');
          this.router.navigate([PageRoute.PATH_HOME], { replaceUrl: true });
        }
      });
    }
  }

  handlePeriodEvent(e: CountdownEvent) {
    if (e.action === 'done') {
      this.periodTimeCount = 0;
      this.changeDetectorRef.detectChanges();
      this.couponService.loadFetchCouponDetail();
    }
  }

  useScanCopayCoupon(event: any, mode?: string) {
    this.setGATag(event, `${AnalyticCategory.COUPON_DETAILS}_ScanPTP`);
    this.webviewToNativeInterfaceService.trackAction(
      {
        action: AnalyticAction.CLICK,
        category: AnalyticCategory.COUPON_DETAILS,
        label: `${AnalyticCategory.COUPON_DETAILS}_ScanPTP`,
        firebase_screen: AnalyticCategory.COUPON_DETAILS,
      },
      [
        {
          key: AnalyticFirebaseParam.CAMPAIGN_ID,
          value: this.couponDetail?.campaignCode!
        }
      ]
    );

    this.clickedRedeem = true;
    this.couponService.loadPermissionUseCoupon(this.couponId, this.couponDetail!.couponOrder);
    this.couponService.permissionUseCoupon$.pipe(filter((v) => !isNil(v.permissionUseCoupon)), take(1))
      .subscribe((response) => {

        if (!response.permissionUseCoupon) {
          this.displayPermissionError(response, CouponActionType.USE);
          return;
        }

        this.sofList = mappingUserSOFWithCoupon(this.userSOF, response.sof);
        this.couponService.loadReserveCouponOrder(this.couponId, this.couponDetail!.couponOrder!, mode);
        this.couponService.clearPermissionUseCoupon();
      });

    this.couponService.reserveCouponOrder$.pipe(filter((v) => Boolean(v.result)), take(1))
      .subscribe((response) => {
        if (response.result.code == ResponseCode.ERROR_NOT_REGISTERED || response.result.code == ResponseCode.ERROR_REGISTERING) {
          const firebaseParams: { [key: string]: FirebaseParam[] } = {
            [ResponseCode.ERROR_NOT_REGISTERED]: [
              {
                key: AnalyticFirebaseParam.CAMPAIGN_ID,
                value: this.couponDetail!.campaignCode
              }
            ],
            [ResponseCode.ERROR_REGISTERING]: [
              {
                key: AnalyticFirebaseParam.CAMPAIGN_ID,
                value: this.couponDetail!.campaignCode,
              }
            ]
          };

          const modalObj = checkRegisterStatus(response.result.code, this.unRegisteredCallbackUrl, this.pendingRegisteredCallbackUrl, firebaseParams);

          this.modalService.openModal(ModalTemplateComponent, { data: modalObj }).pipe(take(1)).subscribe(() => {
            this.clickedRedeem = false;
            this.couponService.clearReserveCouponOrder();
          });
          return;
        }

        if (response.result.code == ResponseCode.ERROR_NOT_ELIGIBLE) {
          const firebaseParams: { [key: string]: FirebaseParam[] } = {
            [ResponseCode.ERROR_NOT_ELIGIBLE]: [
              {
                key: AnalyticFirebaseParam.CAMPAIGN_ID,
                value: this.couponDetail!.campaignCode
              }
            ],
          };

          const modalObj = handleErrorCouponStatus(response.result.code, firebaseParams);
          this.modalService.openModal(ModalTemplateComponent, { data: modalObj }).pipe(take(1)).subscribe(() => {
            this.clickedRedeem = false;
            this.changeDetectorRef.detectChanges();
            this.couponService.clearReserveCouponOrder();
            this.couponService.loadFetchCouponDetail();
          });
          return;
        }

        if (response.result.code == ResponseCode.SUCCESS) {
          // check sof for navigate to register
          if (response.data.isNavigate) {
            const firebaseParams: FirebaseParam[] = [
              {
                key: AnalyticFirebaseParam.CAMPAIGN_ID,
                value: this.couponDetail!.campaignCode
              }
            ];
            const modalObj = checkRegisterStatusAndType(response.data.navigationData, this.unRegisteredCallbackUrl, firebaseParams);
            this.modalService.openModal(ModalTemplateComponent, { data: modalObj }).pipe(take(1)).subscribe((result) => {
              if (result) {
                this.webviewToNativeInterfaceService.interfaceToNative(
                  {
                    command: interfaceToNativeCommand.COMMAND_ROUTING,
                    content: {
                      destination: result,
                    }
                  }
                );
              }
            });
            this.couponService.clearReserveCouponOrder();
            this.clickedRedeem = false;
            return;
          }

          this.couponService.clearReserveCouponOrder();
          if (mode === 'ScanCoPay' && !this.stepRouteConfig.includes(PageRoute.PATH_COUPON_LIST)) {
            this.webviewToNativeInterfaceService.interfaceToNative(
              {
                command: interfaceToNativeCommand.COMMAND_ROUTING,
                content: {
                  destination: interfaceToNativeContentDestination.DESTINATION_SCAN_COPAY,
                  sof: this.sofList,
                  campaignId: this.couponId,
                  callbackMode: "D",
                  additionalParams: `${this.keywordParams}${this.keywordParams !== '' ? '&' + this.categorySelected : this.categorySelected}`
                }
              }
            );
            if (this.navigateCoPayPaymentUrl) {
              this.navigateCoPayPaymentUrl.nativeElement.click();
            }
          } else {
            if (this.navigateCoPayPaymentUrl) {
              this.navigateCoPayPaymentUrl.nativeElement.click();
            }
          }
          this.clickedRedeem = false;
        } else {
          const modalObj: IModalTemplate = {
            title: modalWording.outOfService,
            detail: modalWording.pleaseTryAgainLater,
            analyticModal: {
              eventCategory: AnalyticCategory.COUPON_API_ERROR,
              firebaseParam: [
                {
                  key: AnalyticFirebaseParam.CAMPAIGN_ID,
                  value: this.couponDetail?.campaignCode!
                }
              ]
            },
            button: [{ name: modalWording.ok, action: "", eventLabel: AnalyticLabel.OK }],
            deeplinkCallbackUrl: undefined
          };

          this.modalService.openModal(ModalTemplateComponent, { data: modalObj }).pipe(take(1)).subscribe(() => {
            this.clickedRedeem = false;
            this.couponService.clearReserveCouponOrder();
          });
        }
      });
  }

  processTextLinkT(title: string) {
    const tagADetectionRegex = /<a[\s]+([^>]+)>/gi;
    this.sanitizer.sanitize(SecurityContext.HTML, title);
    if (tagADetectionRegex.test(title)) {
      title.match(tagADetectionRegex)?.map((element) => {
        title = title.replace(element, `${element.slice(0, -1)} class="link-navigation-on-detail">`);
      })
    }
    return title;
  }

  navigateExternalURL(event: any) {
    event.preventDefault();
    const externalURL = event.target.getAttribute('href') ?? '';

    if (externalURL) {
      // for use Jsbridge
      this.setGATag(event, `${AnalyticCategory.COUPON_DETAILS}_ExternalURL`);
      this.webviewToNativeInterfaceService.trackAction(
        {
          action: AnalyticAction.CLICK,
          category: AnalyticCategory.COUPON_DETAILS,
          label: `${AnalyticCategory.COUPON_DETAILS}_ExternalURL`,
          firebase_screen: AnalyticCategory.COUPON_DETAILS,
        },
        [
          {
            key: AnalyticFirebaseParam.CAMPAIGN_ID,
            value: this.couponDetail?.campaignCode!
          }
        ]
      );

      const modalObj: IModalTemplate = {
        title: modalWording.confirmNavigateExternalUrl,
        detail: modalWording.exitFromAppGoToParner,
        analyticModal: {
          eventCategory: AnalyticCategory.COUPON_NAVIGATE_EXTERNAL_URL,
          firebaseParam: [
            {
              key: AnalyticFirebaseParam.CAMPAIGN_ID,
              value: this.couponDetail?.campaignCode!
            }
          ]
        },
        button: [
          { name: modalWording.cancel, action: "", eventLabel: AnalyticLabel.CANCEL },
          { name: modalWording.confirm, action: "confirm", eventLabel: AnalyticLabel.CONFIRM }
        ],
      }

      this.modalService.openModal(ModalTemplateComponent, { data: modalObj }).pipe(take(1)).subscribe((result) => {
        if (result == "confirm") {
          window.location.href = this.sanitizer.sanitize(SecurityContext.URL, externalURL);
        }
      });
    }
  }

  navigatCopayExternalURL(event: any) {
    event.preventDefault();
    let externalURL = this.couponDetail!.copayNavigation!.url;

    this.couponService.loadPermissionUseCoupon(this.couponId, this.couponDetail!.couponOrder, true);
    this.couponService.permissionUseCoupon$.pipe(filter((v) => !isNil(v.permissionUseCoupon)), take(1))
      .subscribe((response) => {

        if (!response.permissionUseCoupon) {
          this.displayPermissionError(response, CouponActionType.USE, true);
          return;
        }

        if (externalURL) {
          // for use Jsbridge
          this.setGATag(event, `${AnalyticCategory.COUPON_DETAILS}_ExternalURL`);
          this.webviewToNativeInterfaceService.trackAction(
            {
              action: AnalyticAction.CLICK,
              category: AnalyticCategory.COUPON_DETAILS,
              label: `${AnalyticCategory.COUPON_DETAILS}_ExternalURL`,
              firebase_screen: AnalyticCategory.COUPON_DETAILS,
            },
            [
              {
                key: AnalyticFirebaseParam.CAMPAIGN_ID,
                value: this.couponDetail?.campaignCode!
              }
            ]
          );

          const modalObj: IModalTemplate = {
            title: modalWording.confirmNavigateExternalUrl,
            detail: modalWording.exitFromAppGoToParner,
            analyticModal: {
              eventCategory: AnalyticCategory.COUPON_NAVIGATE_EXTERNAL_URL,
              firebaseParam: [
                {
                  key: AnalyticFirebaseParam.CAMPAIGN_ID,
                  value: this.couponDetail?.campaignCode!
                }
              ]
            },
            button: [
              { name: modalWording.cancel, action: "", eventLabel: AnalyticLabel.CANCEL },
              { name: modalWording.confirm, action: "confirm", eventLabel: AnalyticLabel.CONFIRM }
            ],
          }

          this.modalService.openModal(ModalTemplateComponent, { data: modalObj }).pipe(take(1)).subscribe((result) => {
            if (result == "confirm") {
              window.location.href = this.utilService.safeURL(externalURL);
            }
          });
        }

        this.couponService.clearPermissionUseCoupon();
      });
  }

  usePaymentTopup(event: any, mode?: string) {
    event.preventDefault();
    this.setGATag(event, `${AnalyticCategory.COUPON_DETAILS}_${this.couponDetail?.copayNavigation.destination}`);
    this.webviewToNativeInterfaceService.trackAction(
      {
        action: AnalyticAction.CLICK,
        category: AnalyticCategory.COUPON_DETAILS,
        label: `${AnalyticCategory.COUPON_DETAILS}_${this.couponDetail?.copayNavigation.destination}`,
        firebase_screen: AnalyticCategory.COUPON_DETAILS,
      },
      [
        {
          key: AnalyticFirebaseParam.CAMPAIGN_ID,
          value: this.couponDetail?.campaignCode!
        }
      ]
    );
    this.clickedRedeem = true;
    this.couponService.loadPermissionUseCoupon(this.couponId, this.couponDetail!.couponOrder, true);
    this.couponService.permissionUseCoupon$.pipe(filter((v) => !isNil(v.permissionUseCoupon)), take(1))
      .subscribe((response) => {

        if (!response.permissionUseCoupon) {
          this.displayPermissionError(response, CouponActionType.USE, true);
          return;
        }

        this.couponService.loadReserveCouponOrder(this.couponId, this.couponDetail!.couponOrder!, mode);
        this.couponService.clearPermissionUseCoupon();
      });

    this.couponService.reserveCouponOrder$.pipe(filter((v) => Boolean(v.result)), take(1))
      .subscribe((response) => {
        if (response.result.code == ResponseCode.ERROR_NOT_REGISTERED || response.result.code == ResponseCode.ERROR_REGISTERING) {
          const firebaseParams: { [key: string]: FirebaseParam[] } = {
            [ResponseCode.ERROR_NOT_REGISTERED]: [
              {
                key: AnalyticFirebaseParam.CAMPAIGN_ID,
                value: this.couponDetail!.campaignCode
              }
            ],
            [ResponseCode.ERROR_REGISTERING]: [
              {
                key: AnalyticFirebaseParam.CAMPAIGN_ID,
                value: this.couponDetail!.campaignCode,
              }
            ]
          };

          const modalObj = checkRegisterStatus(response.result.code, this.unRegisteredCallbackUrl, this.pendingRegisteredCallbackUrl, firebaseParams);

          this.modalService.openModal(ModalTemplateComponent, { data: modalObj }).pipe(take(1)).subscribe(() => {
            this.clickedRedeem = false;
            this.couponService.clearReserveCouponOrder();
          });
          return;
        }

        if (response.result.code == ResponseCode.ERROR_NOT_ELIGIBLE) {
          const firebaseParams: { [key: string]: FirebaseParam[] } = {
            [ResponseCode.ERROR_NOT_ELIGIBLE]: [
              {
                key: AnalyticFirebaseParam.CAMPAIGN_ID,
                value: this.couponDetail!.campaignCode
              }
            ],
          };

          const modalObj = handleErrorCouponStatus(response.result.code, firebaseParams);
          this.modalService.openModal(ModalTemplateComponent, { data: modalObj }).pipe(take(1)).subscribe(() => {
            this.clickedRedeem = false;
            this.changeDetectorRef.detectChanges();
            this.couponService.clearReserveCouponOrder();
            this.couponService.loadFetchCouponDetail();
          });
          return;
        }

        if (response.result.code == ResponseCode.SUCCESS) {
          // check sof for navigate to register
          if (response.data.isNavigate) {
            const firebaseParams: FirebaseParam[] = [
              {
                key: AnalyticFirebaseParam.CAMPAIGN_ID,
                value: this.couponDetail!.campaignCode
              }
            ];
            const modalObj = checkRegisterStatusAndType(response.data.navigationData, this.unRegisteredCallbackUrl, firebaseParams);
            this.modalService.openModal(ModalTemplateComponent, { data: modalObj }).pipe(take(1)).subscribe((result) => {
              if (result) {
                this.webviewToNativeInterfaceService.interfaceToNative(
                  {
                    command: interfaceToNativeCommand.COMMAND_ROUTING,
                    content: {
                      destination: result,
                    }
                  }
                );
              }
            });
            this.couponService.clearReserveCouponOrder();
            this.clickedRedeem = false;
            return;
          }

          this.couponService.clearReserveCouponOrder();
          this.clickedRedeem = false;
          if (this.couponDetail?.copayNavigation?.destination) {
            this.webviewToNativeInterfaceService.interfaceToNative(
              {
                command: interfaceToNativeCommand.COMMAND_ROUTING,
                content: {
                  destination: this.couponDetail?.copayNavigation.destination,
                  payeeCode: this.couponDetail?.copayNavigation.compCode,
                  campaignId: this.couponId,
                  channel: "PT",
                  callbackMode: "D",
                  additionalParams: `${this.keywordParams}${this.keywordParams !== '' ? '&' + this.categorySelected : this.categorySelected}`
                }
              }
            );
          }
        } else {
          const modalObj: IModalTemplate = {
            title: modalWording.outOfService,
            detail: modalWording.pleaseTryAgainLater,
            analyticModal: {
              eventCategory: AnalyticCategory.COUPON_API_ERROR,
              firebaseParam: [
                {
                  key: AnalyticFirebaseParam.CAMPAIGN_ID,
                  value: this.couponDetail?.campaignCode!
                }
              ]
            },
            button: [{ name: modalWording.ok, action: "", eventLabel: AnalyticLabel.OK }],
            deeplinkCallbackUrl: undefined
          };

          this.modalService.openModal(ModalTemplateComponent, { data: modalObj }).pipe(take(1)).subscribe(() => {
            this.clickedRedeem = false;
            this.couponService.clearReserveCouponOrder();
          });
        }
      });
  }

  // Improve method to return value
  handlePeriodTimer(status: string) {
    const currentDateTime: any = new Date();
    let countPeriodStart: number = 0;
    let countPeriodEnd: number = 0;
    let periodCountDown: number = 0;

    // Check calculate case for active or redeem

    if (status === CouponStatus.UNCOLLECTED) {
      countPeriodStart = getPeriodTime(currentDateTime, this.couponDetail!.couponStartDatetime);
      countPeriodEnd = getPeriodTime(currentDateTime, this.couponDetail!.couponEndDatetime);
    }
    if (status === CouponStatus.NEW || status === CouponStatus.COLLECTED) {
      countPeriodStart = getPeriodTime(currentDateTime, this.couponDetail!.redemptionStartDateTime);
      countPeriodEnd = getPeriodTime(currentDateTime, this.couponDetail!.redemptionEndDateTime);
    }

    if (status === CouponStatus.REDEEMED && this.couponDetail?.hasCountdownTimer === false) {
      countPeriodEnd = getPeriodTime(currentDateTime, this.couponDetail!.redemptionEndDateTime);
    }

    if (countPeriodStart > 0 && countPeriodEnd > 0)
      periodCountDown = countPeriodStart > countPeriodEnd ? countPeriodEnd : countPeriodStart;
    else
      periodCountDown = countPeriodStart === 0 ? countPeriodEnd : countPeriodStart;

    if (periodCountDown <= 3600000) {
      this.periodTimeCount = Math.ceil(periodCountDown / 1000);
    }
  }

  displayPermissionError(permisionCoupon: IPermissionUseCouponResponse, couponAction: CouponActionType, isNavigate: boolean = false) {
    if (isNavigate && permisionCoupon?.status && (permisionCoupon?.status === CouponStatus.OUT_OF_QUOTA || permisionCoupon?.status === CouponStatus.OUT_OF_USED_QUOTA || permisionCoupon?.status === CouponStatus.TEMPO_OUT_OF_QUOTA)) {
      const firebaseParams: { [key: string]: FirebaseParam[] } = {
        [ResponseCode.ERROR_OUT_OF_QUOTA]: this.couponChannel === CouponChannel.NEXT ?
          [
            {
              index: 4,
              key: AnalyticFirebaseParam.INFORMATION,
              value: this.couponDetail?.couponTitle!
            }
          ] : [
            {
              key: AnalyticFirebaseParam.CAMPAIGN_ID,
              value: this.couponDetail?.campaignCode!
            }
          ],
      };
      const modalObj = handleErrorCouponStatus(ResponseCode.ERROR_OUT_OF_QUOTA, firebaseParams);
      this.modalService.openModal(ModalTemplateComponent, { data: modalObj }).pipe(take(1)).subscribe(() => {
        this.removeStepRouteConfig(true);
        this.periodTimeCount = 0;
        this.couponService.clearPermissionUseCoupon();
      });
    } else {
      if (permisionCoupon.result?.code == ResponseCode.ERROR_COUPON_DETAIL_ELIGIBLE_NOTFOUND) {
        const firebaseParams: { [key: string]: FirebaseParam[] } = {
          [ResponseCode.ERROR_COUPON_DETAIL_ELIGIBLE_NOTFOUND]: this.couponChannel === CouponChannel.NEXT ?
            [
              {
                index: 4,
                key: AnalyticFirebaseParam.INFORMATION,
                value: this.couponDetail?.couponTitle!
              }
            ] : [
              {
                key: AnalyticFirebaseParam.CAMPAIGN_ID,
                value: this.couponDetail?.campaignCode!
              }
            ],
        };
        const modalObj: IModalTemplate = handleDisplayPermissionUse(ResponseCode.ERROR_COUPON_DETAIL_ELIGIBLE_NOTFOUND, couponAction, firebaseParams);
        this.modalService.openModal(ModalTemplateComponent, { data: modalObj }).pipe(take(1)).subscribe((result) => {
          if (result == "confirm") {
            this.couponService.clearPermissionUseCoupon();
            if (couponAction == CouponActionType.COLLECT) {
              this.removeStepRouteConfig(true);
            } else {
              this.periodTimeCount = 0;
              this.changeDetectorRef.detectChanges();
              this.couponService.loadFetchCouponDetail();
            }
          }
        });
      } else {
        const firebaseParams: { [key: string]: FirebaseParam[] } = {
          [ResponseCode.ERROR_API]: this.couponChannel === CouponChannel.NEXT ?
            [
              {
                index: 5,
                key: AnalyticFirebaseParam.HTTP_STATUS_CODE,
                value: permisionCoupon?.status
              },
              {
                index: 6,
                key: AnalyticFirebaseParam.ERROR_CODE,
                value: permisionCoupon.result?.code
              },
              {
                index: 7,
                key: AnalyticFirebaseParam.IS_FINANCIAL_TRANSACTION_SYSTEM_ERROR,
                value: 'False'
              },
              {
                index: 8,
                key: AnalyticFirebaseParam.ERROR_TITLE,
                value: permisionCoupon.result?.message
              }
            ] : [
              {
                key: AnalyticFirebaseParam.CAMPAIGN_ID,
                value: this.couponDetail?.campaignCode!
              }
            ],
        };
        const modalObj: IModalTemplate = handleDisplayPermissionUse(ResponseCode.ERROR_API, couponAction, firebaseParams);
        this.modalService.openModal(ModalTemplateComponent, { data: modalObj }).pipe(take(1)).subscribe((result) => {
          if (result == "confirm") {
            this.couponService.clearPermissionUseCoupon();
            if (permisionCoupon.result?.code === ResponseCode.SUCCESS) {
              this.periodTimeCount = 0;
              this.changeDetectorRef.detectChanges();
              this.couponService.loadFetchCouponDetail();
            } else {
              this.removeStepRouteConfig(true);
            }
          }
        });
      }
    }
  }

  setGATag(event: any, eventLabel: string) {
    const gaObj: CouponEventTagAction = {
      eventName: AnalyticCategory.COUPON_DETAILS,
      eventCategory: AnalyticCategory.COUPON_DETAILS,
      eventLabel: eventLabel,
      eventAction: AnalyticAction.CLICK,
      campaignCode: this.couponDetail?.campaignCode,
      couponChannel: this.couponChannel ?? ''
    }
    this.couponGAService.trackActionGATag(gaObj)
  }

  setGATagBack(event: any) {
    this.setGATag(event, `${AnalyticCategory.COUPON_DETAILS}_Back`);

    this.webviewToNativeInterfaceService.trackAction(
      {
        action: AnalyticAction.CLICK,
        category: AnalyticCategory.COUPON_DETAILS,
        label: `${AnalyticCategory.COUPON_DETAILS}_Back`,
        firebase_screen: AnalyticCategory.COUPON_DETAILS,
      },
      [
        {
          key: AnalyticFirebaseParam.CAMPAIGN_ID,
          value: this.couponDetail?.campaignCode!
        }
      ]
    );
  }

  setGATagUseLater(event: any) {
    this.setGATag(event, `${AnalyticCategory.COUPON_DETAILS}_UseLater`);

    this.webviewToNativeInterfaceService.trackAction(
      {
        action: AnalyticAction.CLICK,
        category: AnalyticCategory.COUPON_DETAILS,
        label: `${AnalyticCategory.COUPON_DETAILS}_UseLater`,
        firebase_screen: AnalyticCategory.COUPON_DETAILS,
      },
      [
        {
          key: AnalyticFirebaseParam.CAMPAIGN_ID,
          value: this.couponDetail?.campaignCode!
        }
      ]
    );
  }

  loadAnimationCollectButton(value: boolean = false) {
    const wrapCouponClass = document?.querySelector(".coupon-list-card")
    const loadpageClass = document?.querySelector(".loading-page");
    if (value) {
      wrapCouponClass?.classList?.add('disabled-click-action')
      loadpageClass?.classList?.add('loading-style')
    } else {
      wrapCouponClass?.classList?.remove('disabled-click-action')
      loadpageClass?.classList?.remove('loading-style')
    }
  }
  // Support PT Old version !! Remove next phase
  setSelectedCategoryOrSearchKeywordValue() {
    this.couponService.selectedCategoryId$.pipe(filter(v => v !== '0'), take(1))
      .subscribe((categoryId) => {
        localStorage.setItem("selectedCategory", categoryId!);
      });

    this.couponService.searchKeyword$.pipe(filter(v => !isEmpty(v)), take(1))
      .subscribe((searchKeyword) => {
        localStorage.setItem("searchKeyword", searchKeyword!);
      });

    this.couponService.applyCouponKeyword$.pipe(filter(v => !isEmpty(v)), take(1))
      .subscribe((applyCouponKeyword) => {
        localStorage.setItem("applyCouponKeyword", applyCouponKeyword!);
      });
  }

}
