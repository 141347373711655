import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { Subject, filter, take, takeUntil } from 'rxjs';
import { modalWording, ResponseCode } from 'src/app/configs/app-config';
import { CouponCardDialogTemplate, CouponCardTemplate } from 'src/app/configs/coupon-card-config';
import { PageRoute } from 'src/app/configs/route-config';
import { calculateTimeDiff, convertToLocaleDateString, convertToLocaleTimeString } from 'src/app/helpers/date-time.helper';
import { IModalTemplate } from 'src/app/model/coupon-modal.model';
import { SelectedCoupon } from 'src/app/root-store/root-store.model';
import { RootStoreService } from 'src/app/root-store/root-store.service';
import { ModalService } from 'src/app/service/modal.service';
import { CouponService } from 'src/shared/coupon.service';
import { ModalTemplateComponent } from '../modal-template/modal-template.component';
import { isEmpty, isNil } from 'lodash';
import { AdditionalParams, BadgeLabel, CopayNavigationType, CouponActionType, CouponChannel, CouponEntryPoint, CouponMethod, CouponStatus, DeductionType, InternalNavigationType, PeriodStatus, UseActionTypeModalTemplate } from 'src/app/model/coupon-enum.model';
import { AnalyticCategory, AnalyticFirebaseParam, AnalyticLabel, interfaceToNativeCommand, interfaceToNativeContentDestination } from 'src/app/webview-to-native-interface/webview-to-native-interface-enum.model';
import { checkRegisterStatus, checkRegisterStatusAndType, handleDisplayPermissionUse, handleErrorCouponStatus, mappingUserSOFWithCoupon } from 'src/app/helpers/coupon.helper';
import { FirebaseParam } from 'src/app/webview-to-native-interface/webview-to-native-interface.model';
import { IPermissionUseCouponResponse } from 'src/app/model/coupon-model.model';
import { WebviewToNativeInterfaceService } from 'src/app/webview-to-native-interface/webview-to-native-interface.service';

@Component({
  selector: 'coupon-card-horizontal-dialog',
  templateUrl: './coupon-card-horizontal-dialog.component.html',
  styleUrls: ['./coupon-card-horizontal-dialog.component.scss']
})
export class CouponCardHorizontalDialogComponent implements OnInit {
  @ViewChildren('pickCouponCallbackUrl') pickCouponCallbackUrl: QueryList<ElementRef>;
  @ViewChildren("couponCard") couponCards: QueryList<ElementRef>;
  @Input() prefixId: string = '';
  @Input() couponDialogTemplate: string;
  @Input() coupons: any;
  @Input() couponChannel: string;
  @Input() entryPoint: string;
  @Input() amount: string;
  @Input() userSOF: string[] = [];
  @Output() onActionPickCoupon: EventEmitter<any> = new EventEmitter<any>();
  @Output() onActionUseCouponPaymentTopup: EventEmitter<any> = new EventEmitter<any>();
  @Output() onActionUseCouponExternalURL: EventEmitter<any> = new EventEmitter<any>();
  @Output() onActionUseCoupon: EventEmitter<any> = new EventEmitter<any>();
  @Output() onActionSetStepRoute: EventEmitter<any> = new EventEmitter<any>();
  @Output() emitOpenDialogButtom: EventEmitter<any> = new EventEmitter<any>();
  @Output() onActionNavigateToCouponDetail: EventEmitter<any> = new EventEmitter<any>();
  @Output() onActionTrackGAUseCoupon: EventEmitter<any> = new EventEmitter<any>();
  @Output() onActionSetToCloseDialog: EventEmitter<any> = new EventEmitter<any>();

  CouponCardDialogTemplate = CouponCardDialogTemplate;
  CouponStatus = CouponStatus;
  PeriodStatus = PeriodStatus;
  BadgeLabel = BadgeLabel;
  CouponMethod = CouponMethod;
  CopayNavigationType = CopayNavigationType;
  InternalNavigationType = InternalNavigationType;
  chooseCouponCallbackUrl = this.rootStoreService.jwtSession!.chooseCouponCallbackUrl;
  paymentCallbackUrl = this.rootStoreService.jwtSession!.paymentCallbackUrl;
  registerCallbackUrl = this.rootStoreService.jwtSession!.registerCallbackUrl ?? "mycoupon";
  processingCallbackUrl = this.rootStoreService.jwtSession!.processingCallbackUrl;
  PageRoute = PageRoute;
  DeductionType = DeductionType;
  isInternalNavi = true //mock
  isUseExternal = false //mock
  sofList: string[] = [];
  callbackMode: string = '';
  categorySelected: string = '';
  private _destroy = new Subject<void>();

  constructor(
    private rootStoreService: RootStoreService,
    private couponService: CouponService,
    private modalService: ModalService,
    private changeDetectorRef: ChangeDetectorRef,
    private webviewToNativeInterfaceService: WebviewToNativeInterfaceService,
  ) { }

  ngOnInit(): void {
    this.callbackMode = this.couponDialogTemplate === CouponCardDialogTemplate.COUPON_HOME_BOTTOM_DIALOG ? 'H' : 'M';
    this.couponService.selectedCategoryId$
      .pipe(filter(v => v !== '0' && this.couponDialogTemplate === CouponCardDialogTemplate.COUPON_HOME_BOTTOM_DIALOG), takeUntil(this._destroy))
      .subscribe((categoryId) => {
        this.categorySelected = `${AdditionalParams.CATEGORY}=${categoryId}`;
      });
  }

  ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
  }

  navigateToCouponDetail(coupon: any, index: number = 0) {

    let selectCoupon = {
      couponId: coupon.couponId,
      numberOfCoupons: coupon.numberOfCoupons,
      orders: [] as any
    };

    if (coupon.orders && coupon.orders.length > 0)
      selectCoupon.orders = [coupon.orders[index]] ?? []

    this.onActionNavigateToCouponDetail.emit(selectCoupon);
  }

  navigateFormHomepageToCouponDetail(coupon: any, index: number = 0) {

    let selectCoupon = {
      couponId: coupon.couponId,
      numberOfCoupons: coupon.numberOfCoupons,
      couponInfo: [] as any
    };

    if (coupon.couponInfo && coupon.couponInfo.length > 0)
      selectCoupon.couponInfo = [coupon.couponInfo[index]] ?? []

    this.onActionNavigateToCouponDetail.emit(selectCoupon);
  }

  pickCoupon(event: Event, i: number, couponId: string, couponOrder?: number, isRedeemable?: boolean, redemptionEndDateTime?: string, redemptionStartDateTime?: string) {
    const objEmit = {
      event: event,
      i: i,
      couponId: couponId,
      couponOrder: couponOrder,
      isRedeemable: isRedeemable,
      redemptionEndDateTime: redemptionEndDateTime,
      redemptionStartDateTime: redemptionStartDateTime
    }
    this.onActionPickCoupon.emit(objEmit)
  }

  useCoupon(coupon: any, couponOrder: number, index: number) {
    const cardEl = this.getCouponCard(index);
    this.disableActionCard(cardEl, true);
    const selectCoupon = {
      couponId: coupon.couponId,
      campaignCode: coupon.campaignCode,
      orders: [] as any
    }

    if (coupon.orders && coupon.orders.length > 0)
      selectCoupon.orders = [coupon.orders[index]] ?? []

    this.onActionUseCouponForBottomDialogContent(cardEl, selectCoupon, couponOrder, PageRoute.MY_COUPON);

  }

  useCouponForHomePage(coupon: any, couponOrder: number, index: number) {
    // this.disableActionButton(event, true);
    const cardEl = this.getCouponCard(index);
    this.disableActionCard(cardEl, true);

    const selectCoupon = {
      couponId: coupon.couponId,
      campaignCode: coupon.campaignCode,
      hasCountdownTimer: coupon.hasCountdownTimer,
      couponInfo: [] as any
    }

    if (coupon.couponInfo && coupon.couponInfo.length > 0)
      selectCoupon.couponInfo = [coupon.couponInfo[index]] ?? []

    this.onActionUseCouponForBottomDialogContent(cardEl, selectCoupon, couponOrder, PageRoute.HOME);
  }

  usePaymentTopup(coupon: any, couponOrder: number, pageRoute: PageRoute, index: number, mode?: string) {
    const cardEl = this.getCouponCard(index);
    this.disableActionCard(cardEl, true);
    const selectCoupon = {
      element: cardEl,
      coupon: {
        couponId: coupon.couponId,
        campaignCode: coupon.campaignCode,
        orders: [] as any,
      },
      destination: coupon.copayNavigation.destination,
      compCode: coupon.copayNavigation.compCode
    }

    if (coupon.orders && coupon.orders.length > 0) {
      selectCoupon.coupon.orders = [coupon.orders[index]] ?? []
    }

    this.onActionTrackGAUseCoupon.emit();

    this.couponService.loadPermissionUseCoupon(coupon.couponId, couponOrder, true);
    this.couponService.permissionUseCoupon$.pipe(filter((v) => !isNil(v.permissionUseCoupon)), take(1))
      .subscribe((response) => {
        if (!response?.permissionUseCoupon) {
          this.displayPermissionError(pageRoute, coupon.campaignCode, response, true);
          return;
        }
        this.couponService.loadReserveCouponOrder(coupon.couponId, couponOrder, mode);
        this.couponService.clearPermissionUseCoupon();
      });

    this.couponService.reserveCouponOrder$.pipe(filter((v) => Boolean(v.result)), take(1))
      .subscribe((response) => {
        if (response.result.code == ResponseCode.ERROR_NOT_REGISTERED || response.result.code == ResponseCode.ERROR_REGISTERING) {
          const unRegisteredCallbackUrl = this.registerCallbackUrl?.replace('${campaignId}', coupon.couponId) + '&callbackMode=H' || "";
          const pendingRegisteredCallbackUrl = this.processingCallbackUrl?.replace('${campaignId}', coupon.couponId) || "";
          const firebaseParams: { [key: string]: FirebaseParam[] } = {
            [ResponseCode.ERROR_NOT_REGISTERED]: [
              {
                key: AnalyticFirebaseParam.CAMPAIGN_ID,
                value: coupon.campaignCode
              }
            ],
            [ResponseCode.ERROR_REGISTERING]: [
              {
                key: AnalyticFirebaseParam.CAMPAIGN_ID,
                value: coupon.campaignCode,
              }
            ]
          };

          const modalObj = checkRegisterStatus(response.result.code, unRegisteredCallbackUrl, pendingRegisteredCallbackUrl, firebaseParams);

          this.modalService.openModal(ModalTemplateComponent, { data: modalObj }).pipe(take(1)).subscribe(() => {
            this.disableActionCard(cardEl, false);
            this.couponService.clearReserveCouponOrder();
          });
          return;
        }

        if (response.result.code == ResponseCode.ERROR_NOT_ELIGIBLE) {
          const firebaseParams: { [key: string]: FirebaseParam[] } = {
            [ResponseCode.ERROR_NOT_ELIGIBLE]: [
              {
                key: AnalyticFirebaseParam.CAMPAIGN_ID,
                value: coupon.campaignCode
              }
            ],
          };

          const modalObj = handleErrorCouponStatus(response.result.code, firebaseParams);
          this.modalService.openModal(ModalTemplateComponent, { data: modalObj }).pipe(take(1)).subscribe(() => {
            this.disableActionCard(cardEl, false);
            this.changeDetectorRef.detectChanges();
            this.couponService.clearReserveCouponOrder();
            this.setToCloseDialog(pageRoute)
          });
          return;
        }

        if (response.result.code == ResponseCode.SUCCESS) {
          // Support PT Old version !! Remove deeplink next phase
          const unRegisteredCallbackUrl = this.registerCallbackUrl?.replace('${campaignId}', coupon.couponId) + '&callbackMode=H' || "";
          // check sof for navigate to register
          if (response.data.isNavigate) {
            const firebaseParams: FirebaseParam[] = [
              {
                key: AnalyticFirebaseParam.CAMPAIGN_ID,
                value: coupon.campaignCode
              }
            ];
            const modalObj = checkRegisterStatusAndType(response.data.navigationData, unRegisteredCallbackUrl, firebaseParams);
            this.modalService.openModal(ModalTemplateComponent, { data: modalObj }).pipe(take(1)).subscribe((result) => {
              if (result) {
                this.webviewToNativeInterfaceService.interfaceToNative(
                  {
                    command: interfaceToNativeCommand.COMMAND_ROUTING,
                    content: {
                      destination: result,
                    }
                  }
                );
              }
            });
            this.couponService.clearReserveCouponOrder();
            this.disableActionCard(cardEl, false);
            return;
          }

          const selectedCoupon = {
            couponId: coupon.couponId,
            hasCountdownTimer: false,
            orders: [] as any,
            couponInfo: [] as any
          }

          if (coupon.orders && coupon.orders.length > 0) {
            selectedCoupon.orders = coupon.orders.filter((order: any) => order.order === couponOrder) ?? [];
          }

          if (coupon.couponInfo && coupon.couponInfo.length > 0) {
            selectedCoupon.hasCountdownTimer = coupon.hasCountdownTimer;
            selectedCoupon.couponInfo = coupon.couponInfo.filter((couponInfo: any) => couponInfo.receivedOrder === couponOrder) ?? [];
          }
          this.saveSelectedCoupon(selectedCoupon, pageRoute);
          this.couponService.clearReserveCouponOrder();
          this.disableActionCard(cardEl, false);
          this.onActionUseCouponPaymentTopup.emit(selectCoupon)
          //check destination
          if (coupon.copayNavigation.destination === InternalNavigationType.PT_PAYMENT || coupon.copayNavigation.destination === InternalNavigationType.PT_TOPUP) {
            this.webviewToNativeInterfaceService.interfaceToNative(
              {
                command: interfaceToNativeCommand.COMMAND_ROUTING,
                content: {
                  destination: coupon.copayNavigation.destination,
                  payeeCode: coupon.copayNavigation.compCode,
                  campaignId: coupon.couponId,
                  channel: "PT",
                  callbackMode: this.callbackMode,
                  additionalParams: `${this.categorySelected}`
                }
              }
            );
          }
        } else {
          const modalObj: IModalTemplate = {
            title: modalWording.outOfService,
            detail: modalWording.pleaseTryAgainLater,
            analyticModal: {
              eventCategory: AnalyticCategory.COUPON_API_ERROR,
              firebaseParam: [
                {
                  key: AnalyticFirebaseParam.CAMPAIGN_ID,
                  value: coupon.campaignCode!
                }
              ]
            },
            button: [{ name: modalWording.ok, action: "", eventLabel: AnalyticLabel.OK }],
            deeplinkCallbackUrl: undefined
          };

          this.modalService.openModal(ModalTemplateComponent, { data: modalObj }).pipe(take(1)).subscribe(() => {
            this.couponService.clearReserveCouponOrder();
            this.disableActionCard(cardEl, false);
          });
        }
      });
  }

  navigateExternalURL(coupon: any, couponOrder: number, index: number) {
    const cardEl = this.getCouponCard(index);
    // this.disableActionCard(cardEl, true);
    const selectCoupon = {
      element: cardEl,
      coupon: {
        couponId: coupon.couponId,
        campaignCode: coupon.campaignCode,
        orders: [] as any,
        couponInfo: [] as any
      },
      externalUrl: coupon.copayNavigation.url
    }
    if (coupon.couponInfo && coupon.couponInfo.length > 0)
      selectCoupon.coupon.couponInfo = [coupon.couponInfo[index]] ?? []
    if (coupon.orders && coupon.orders.length > 0)
      selectCoupon.coupon.orders = [coupon.orders[index]] ?? []
    this.onActionTrackGAUseCoupon.emit();
    this.onActionUseCouponExternalURL.emit(selectCoupon)
  }

  useScanCopayCoupon(coupon: any, couponOrder: number, pageRoute: PageRoute, elementId: string, index: number, mode?: string) {
    // this.disableActionButton(event, true);
    const cardEl = this.getCouponCard(index);
    this.disableActionCard(cardEl, true);

    this.onActionTrackGAUseCoupon.emit();


    this.couponService.loadPermissionUseCoupon(coupon.couponId, couponOrder);
    this.couponService.permissionUseCoupon$.pipe(filter((v) => !isNil(v.permissionUseCoupon)), take(1))
      .subscribe((response) => {
        if (!response.permissionUseCoupon) {
          this.displayPermissionError(pageRoute, coupon.campaignCode, response);
          return;
        }

        this.sofList = mappingUserSOFWithCoupon(this.userSOF, response.sof);
        this.couponService.loadReserveCouponOrder(coupon.couponId, couponOrder, mode);
        this.couponService.clearPermissionUseCoupon();
      });

    this.couponService.reserveCouponOrder$.pipe(filter((v) => Boolean(v.result)), take(1))
      .subscribe((response) => {

        if (response.result.code == ResponseCode.ERROR_NOT_REGISTERED || response.result.code == ResponseCode.ERROR_REGISTERING) {

          const unRegisteredCallbackUrl = this.registerCallbackUrl?.replace('${campaignId}', coupon.couponId) + '&callbackMode=H' || "";
          const pendingRegisteredCallbackUrl = this.processingCallbackUrl?.replace('${campaignId}', coupon.couponId) || "";

          const firebaseParams: { [key: string]: FirebaseParam[] } = {
            [ResponseCode.ERROR_NOT_REGISTERED]: [
              {
                key: AnalyticFirebaseParam.CAMPAIGN_ID,
                value: coupon.campaignCode
              }
            ],
            [ResponseCode.ERROR_REGISTERING]: [
              {
                key: AnalyticFirebaseParam.CAMPAIGN_ID,
                value: coupon.campaignCode
              }
            ]
          };

          const modalObj = checkRegisterStatus(response.result.code, unRegisteredCallbackUrl, pendingRegisteredCallbackUrl, firebaseParams);

          this.modalService.openModal(ModalTemplateComponent, { data: modalObj }).pipe(take(1)).subscribe(() => {
            this.disableActionCard(cardEl, false);
            this.couponService.clearReserveCouponOrder();
          });
          return;
        }

        if (response.result.code == ResponseCode.ERROR_NOT_ELIGIBLE) {
          const firebaseParams: { [key: string]: FirebaseParam[] } = {
            [ResponseCode.ERROR_NOT_ELIGIBLE]: [
              {
                key: AnalyticFirebaseParam.CAMPAIGN_ID,
                value: coupon.campaignCode
              }
            ],
          };
          const modalObj = handleErrorCouponStatus(response.result.code, firebaseParams);
          this.modalService.openModal(ModalTemplateComponent, { data: modalObj }).pipe(take(1)).subscribe(() => {
            this.couponService.clearReserveCouponOrder();
            this.disableActionCard(cardEl, false);
            this.setToCloseDialog(pageRoute)
          });
          return
        }

        if (response.result.code == ResponseCode.SUCCESS) {
          // Support PT Old version !! Remove deeplink next phase
          const unRegisteredCallbackUrl = this.registerCallbackUrl?.replace('${campaignId}', coupon.couponId) + '&callbackMode=H' || "";
          // check sof for navigate to register
          if (response.data.isNavigate) {
            const firebaseParams: FirebaseParam[] = [
              {
                key: AnalyticFirebaseParam.CAMPAIGN_ID,
                value: coupon.campaignCode
              }
            ];
            const modalObj = checkRegisterStatusAndType(response.data.navigationData, unRegisteredCallbackUrl, firebaseParams);
            this.modalService.openModal(ModalTemplateComponent, { data: modalObj }).pipe(take(1)).subscribe((result) => {
              if (result) {
                this.webviewToNativeInterfaceService.interfaceToNative(
                  {
                    command: interfaceToNativeCommand.COMMAND_ROUTING,
                    content: {
                      destination: result,
                    }
                  }
                );
              }
            });
            this.couponService.clearReserveCouponOrder();
            this.disableActionCard(cardEl, false);
            return;
          }

          const selectCoupon = {
            couponId: coupon.couponId,
            hasCountdownTimer: false,
            orders: [] as any,
            couponInfo: [] as any
          }

          if (coupon.orders && coupon.orders.length > 0)
            selectCoupon.orders = coupon.orders.filter((order: any) => order.order === couponOrder) ?? [];

          if (coupon.couponInfo && coupon.couponInfo.length > 0) {

            selectCoupon.hasCountdownTimer = coupon.hasCountdownTimer;
            selectCoupon.couponInfo = coupon.couponInfo.filter((couponInfo: any) => couponInfo.receivedOrder === couponOrder) ?? [];
          }
          this.saveSelectedCoupon(selectCoupon, pageRoute);
          this.couponService.clearReserveCouponOrder();
          if (mode === 'ScanCoPay') {
            // New Version
            this.webviewToNativeInterfaceService.interfaceToNative(
              {
                command: interfaceToNativeCommand.COMMAND_ROUTING,
                content: {
                  destination: interfaceToNativeContentDestination.DESTINATION_SCAN_COPAY,
                  sof: this.sofList,
                  campaignId: coupon.couponId,
                  callbackMode: this.callbackMode,
                  additionalParams: `${this.categorySelected}`
                }
              }
            );
            // Old Version Remove Next phase
            if (this.pickCouponCallbackUrl) {
              const allCoupons = this.pickCouponCallbackUrl.toArray();
              const pickCoupon = allCoupons.find((pickCoupon) => pickCoupon.nativeElement.id === elementId);
              pickCoupon!.nativeElement.click();
            }
          } else {
            if (this.pickCouponCallbackUrl) {
              const allCoupons = this.pickCouponCallbackUrl.toArray();
              const pickCoupon = allCoupons.find((pickCoupon) => pickCoupon.nativeElement.id === elementId);
              pickCoupon!.nativeElement.click();
            }
          }
          this.disableActionCard(cardEl, false);
        } else {
          const modalObj: IModalTemplate = {
            title: modalWording.outOfService,
            detail: modalWording.pleaseTryAgainLater,
            analyticModal: {
              eventCategory: AnalyticCategory.COUPON_API_ERROR,
              firebaseParam: [
                {
                  key: AnalyticFirebaseParam.CAMPAIGN_ID,
                  value: coupon.campaignCode
                }
              ]
            },
            button: [{ name: modalWording.ok, action: "", eventLabel: AnalyticLabel.OK }],
            deeplinkCallbackUrl: undefined
          };

          this.modalService.openModal(ModalTemplateComponent, { data: modalObj }).pipe(take(1)).subscribe(() => {
            this.couponService.clearReserveCouponOrder();
            this.disableActionCard(cardEl, false);
          });
        }
      });
  }
  setStepRouteFlag(event: any) {
    this.onActionSetStepRoute.emit(event);
  }

  setToCloseDialog(pageRoute: PageRoute) {
    this.onActionSetToCloseDialog.emit(pageRoute)
  }

  // bypassCouponCopay(backCallbackUrl: string) {
  //   return this.sanitizer.sanitize(SecurityContext.URL, backCallbackUrl);
  // }

  convertDateFormat(val: any) {
    const date = new Date(val)
    return convertToLocaleDateString(date);
  }

  convertTimeFormat(val: any) {
    const date = new Date(val)
    return convertToLocaleTimeString(date);
  }

  isInChannel(coupon: any) {
    if (coupon.copayChannels && coupon.couponUseMethod == CouponMethod.COPAY) {
      if (coupon.copayChannels[0] === CouponChannel.PT && !coupon.copayChannels.includes(this.couponChannel || '')) {
        return false
      }

      // Scan QR only use on PT
      if (coupon.copayChannels[0] === CouponChannel.PT && coupon.isUseButtonDisplay && (this.entryPoint === CouponEntryPoint.PWP || this.couponChannel !== CouponChannel.PT)) {
        return false
      }

      if (coupon.copayChannels[0] === CouponChannel.PW && (!coupon.isUseButtonDisplay && !coupon.isCoPayNavigate) && this.entryPoint !== CouponEntryPoint.PWP)
        return false;

      if (coupon.copayChannels[0] === CouponChannel.PW && (this.couponChannel !== CouponChannel.PT || (!coupon.isUseButtonDisplay && !coupon.isCoPayNavigate))) {
        return false
      }

      return true;
    }
    return true
  }

  isDisplayBadgeLabel(status: any) {
    const arrStatus = [CouponStatus.REDEEM_CONFIRMED, CouponStatus.EXPIRED, CouponStatus.OUT_OF_QUOTA, CouponStatus.TEMPO_OUT_OF_QUOTA, CouponStatus.OUT_OF_USED_QUOTA, CouponStatus.NOT_ELIGIBLE_TO_USE];
    return !arrStatus.includes(status);
  }

  calculateTimeDiff(dateSent: any) {
    const newDate = new Date(dateSent);
    return calculateTimeDiff(newDate);
  }

  onActionUseCouponForBottomDialogContent(element: ElementRef<any>, coupon: any, couponOrder: number, pageRoute: PageRoute, index: number = 0) {

    this.onActionTrackGAUseCoupon.emit();

    const selectedCoupon = {
      coupon: coupon,
      actionType: ''
    };

    const couponInfo = pageRoute === PageRoute.HOME ? coupon.couponInfo : coupon.orders
    const dateRedeemdiff = this.calculateTimeDiff(pageRoute === PageRoute.HOME ? couponInfo[index].couponEndRedemptionDateTime : couponInfo[index].expirationDate);
    const isCouponRedeemStatus = couponInfo[index].status === CouponStatus.REDEEMED;

    if (dateRedeemdiff < 0 && couponInfo[index].status !== CouponStatus.REDEEMED) {
      selectedCoupon.actionType = UseActionTypeModalTemplate.EXPIRED;
      this.onActionUseCoupon.emit(selectedCoupon);
    } else {
      // Success case
      if (isCouponRedeemStatus) {
        selectedCoupon.actionType = UseActionTypeModalTemplate.REDEEMED;
        this.onActionUseCoupon.emit(selectedCoupon);
      } else {
        this.couponService.loadPermissionUseCoupon(coupon.couponId, couponOrder);
        this.couponService.permissionUseCoupon$
          .pipe(filter((v) => !isNil(v.permissionUseCoupon)), take(1))
          .subscribe((response) => {
            if (!response.permissionUseCoupon) {
              this.displayPermissionError(pageRoute, coupon.campaignCode, response);
              return;
            }

            let txtDetail = "";
            if (pageRoute === PageRoute.HOME) {
              if (coupon.hasCountdownTimer && couponInfo[index].countdownTimeInSeconds! > 0)
                txtDetail = modalWording.afterConfirmCouponHaveTime + (couponInfo[index].countdownTimeInSeconds!) / 60 + modalWording.minute;
            } else {
              if (couponInfo[index].hasCountdownTimer && couponInfo[index].countdownTimeInSeconds > 0)
                txtDetail = modalWording.afterConfirmCouponHaveTime + (couponInfo[index].countdownTimeInSeconds) / 60 + modalWording.minute;
            }

            const modalObj: IModalTemplate = {
              title: modalWording.confirmUseCoupon,
              detail: txtDetail,
              analyticModal: {
                eventCategory: AnalyticCategory.COUPON_CONFIRM_USING,
                firebaseParam: this.couponChannel === CouponChannel.NEXT ?
                  [
                    {
                      index: 4,
                      key: AnalyticFirebaseParam.INFORMATION,
                      value: couponInfo[index].countdownTimeInSeconds > 0 ? (couponInfo[index].countdownTimeInSeconds).toString() : '0'
                    },
                    {
                      index: 4,
                      key: AnalyticFirebaseParam.INFORMATION,
                      value: coupon.couponShortTitle
                    }
                  ] :
                  [
                    {
                      key: AnalyticFirebaseParam.CAMPAIGN_ID,
                      value: coupon.campaignCode!,
                    }
                  ]
              },
              button: [
                { name: modalWording.cancel, action: "", eventLabel: AnalyticLabel.CANCEL },
                { name: modalWording.confirm, action: "confirm", eventLabel: AnalyticLabel.CONFIRM }
              ]
            }
            this.modalService.openModal(ModalTemplateComponent, { data: modalObj }).pipe(take(1)).subscribe((result) => {
              this.disableActionCard(element, false);

              if (result == 'confirm') {
                selectedCoupon.actionType = UseActionTypeModalTemplate.SUCCESS;
                this.onActionUseCoupon.emit(selectedCoupon);
                this.couponService.clearPermissionUseCoupon();
              }
            });
          });
      }
    }
  }

  isDisplayProgressBarLabel(status: any) {
    const arrStatus = [CouponStatus.REDEEM_CONFIRMED, CouponStatus.EXPIRED, CouponStatus.OUT_OF_QUOTA, CouponStatus.TEMPO_OUT_OF_QUOTA, CouponStatus.OUT_OF_USED_QUOTA, CouponStatus.NOT_ELIGIBLE_TO_USE];
    return !arrStatus.includes(status);
  }

  // isDisplayCouponProgressBarLabel(status: string, coupons: any) {
  //   return CouponStatus.REDEEM_CONFIRMED && status !== CouponStatus.EXPIRED && status !== CouponStatus.OUT_OF_QUOTA && status !== CouponStatus.TEMPO_OUT_OF_QUOTA && status !== CouponStatus.OUT_OF_USED_QUOTA;
  // }

  isDisplayButton(status: string) {
    return status !== CouponStatus.REDEEM_CONFIRMED && status !== CouponStatus.EXPIRED && status !== CouponStatus.OUT_OF_QUOTA && status !== CouponStatus.TEMPO_OUT_OF_QUOTA && status !== CouponStatus.OUT_OF_USED_QUOTA && status !== CouponStatus.NOT_ELIGIBLE_TO_USE;
  }

  isDisplayDisableCard(status: string, periodStatus: string, isAboveMinimumSpend: boolean) {
    return status === CouponStatus.OUT_OF_QUOTA || status === CouponStatus.TEMPO_OUT_OF_QUOTA || status === CouponStatus.OUT_OF_USED_QUOTA || (status === CouponStatus.COLLECTED && (periodStatus === PeriodStatus.DATE || periodStatus === PeriodStatus.PERIOD || !isAboveMinimumSpend)) || (status === CouponStatus.NEW && (periodStatus === PeriodStatus.DATE || periodStatus === PeriodStatus.PERIOD));
  }

  mappingSelectedCoupon(coupon: any, index = 0) {
    const selectedCoupon: SelectedCoupon = {
      couponId: coupon.couponId,
      couponCode: !isEmpty(coupon.couponInfo) ? coupon.couponInfo[index].couponCode : coupon.orders[index].couponCode ?? '',
      hasCountdownTimer: !isEmpty(coupon.couponInfo) ? coupon.hasCountdownTimer : coupon.orders[index].hasCountdownTimer ?? false,
      countdownTimeInSeconds: !isEmpty(coupon.couponInfo) ? coupon.couponInfo[index].countdownTimeInSeconds : coupon.orders[index].countdownTimeInSeconds ?? 0,
      order: !isEmpty(coupon.couponInfo) ? coupon.couponInfo[index].receivedOrder : coupon.orders[index].order,
      couponStatus: !isEmpty(coupon.couponInfo) ? coupon.couponInfo[index].status : coupon.orders[index].status
    };
    return selectedCoupon;
  }

  private saveSelectedCoupon(ev: any, pathRoute: PageRoute) {
    const selectedCoupon = this.mappingSelectedCoupon(ev);
    this.couponService.saveSelectedCouponValue(selectedCoupon, pathRoute);
  }

  private displayPermissionError(pathRoute: PageRoute, campaignCode: string, permisionCoupon: IPermissionUseCouponResponse, isNavigate: boolean = false) {
    if (isNavigate && permisionCoupon?.status && (permisionCoupon?.status === CouponStatus.OUT_OF_QUOTA || permisionCoupon?.status === CouponStatus.OUT_OF_USED_QUOTA || permisionCoupon?.status === CouponStatus.TEMPO_OUT_OF_QUOTA)) {
      const firebaseParams: { [key: string]: FirebaseParam[] } = {
        [ResponseCode.ERROR_OUT_OF_QUOTA]: [
          {
            key: AnalyticFirebaseParam.CAMPAIGN_ID,
            value: campaignCode
          }
        ],
      };
      const modalObj = handleErrorCouponStatus(ResponseCode.ERROR_OUT_OF_QUOTA, firebaseParams);
      this.modalService.openModal(ModalTemplateComponent, { data: modalObj }).pipe(take(1)).subscribe((result) => {
        if (result == 'confirm') {
          this.changeDetectorRef.detectChanges();
          this.couponService.clearPermissionUseCoupon();
          if (pathRoute === PageRoute.HOME) {
            this.couponService.loadFetchCoupons();
            return;
          }

          if (pathRoute == PageRoute.MY_COUPON) {
            this.couponService.loadFetchMyCoupon();
            return;
          }

          if (pathRoute == PageRoute.COUPON_LIST) {
            this.couponService.loadFetchCouponList(this.amount, false);
            return;
          }
        }
      });
    } else {
      if (permisionCoupon.result?.code == ResponseCode.ERROR_COUPON_DETAIL_ELIGIBLE_NOTFOUND) {
        const firebaseParams: { [key: string]: FirebaseParam[] } = {
          [ResponseCode.ERROR_COUPON_DETAIL_ELIGIBLE_NOTFOUND]: [
            {
              key: AnalyticFirebaseParam.CAMPAIGN_ID,
              value: campaignCode
            }
          ],
        };
        const modalObj: IModalTemplate = handleDisplayPermissionUse(ResponseCode.ERROR_COUPON_DETAIL_ELIGIBLE_NOTFOUND, CouponActionType.USE, firebaseParams);
        this.modalService.openModal(ModalTemplateComponent, { data: modalObj }).pipe(take(1)).subscribe((result) => {
          if (result == "confirm") {
            this.changeDetectorRef.detectChanges();
            this.couponService.clearPermissionUseCoupon();
            if (pathRoute === PageRoute.HOME) {
              this.couponService.loadFetchCoupons();
              return;
            }

            if (pathRoute == PageRoute.MY_COUPON) {
              this.couponService.loadFetchMyCoupon();
              return;
            }

            if (pathRoute == PageRoute.COUPON_LIST) {
              this.couponService.loadFetchCouponList(this.amount, false);
              return;
            }
          }
        });
      } else {
        const firebaseParams: { [key: string]: FirebaseParam[] } = {
          [ResponseCode.ERROR_API]: this.couponChannel === CouponChannel.NEXT ?
            [
              {
                index: 5,
                key: AnalyticFirebaseParam.HTTP_STATUS_CODE,
                value: permisionCoupon?.status
              },
              {
                index: 6,
                key: AnalyticFirebaseParam.ERROR_CODE,
                value: permisionCoupon.result?.code
              },
              {
                index: 7,
                key: AnalyticFirebaseParam.IS_FINANCIAL_TRANSACTION_SYSTEM_ERROR,
                value: 'False'
              },
              {
                index: 8,
                key: AnalyticFirebaseParam.ERROR_TITLE,
                value: permisionCoupon.result?.message
              }
            ] : [
              {
                key: AnalyticFirebaseParam.CAMPAIGN_ID,
                value: campaignCode
              }
            ],
        };
        const modalObj: IModalTemplate = handleDisplayPermissionUse(ResponseCode.ERROR_API, CouponActionType.USE, firebaseParams);
        this.modalService.openModal(ModalTemplateComponent, { data: modalObj }).pipe(take(1)).subscribe((result) => {
          if (result == "confirm") {
            this.changeDetectorRef.detectChanges();
            this.couponService.clearPermissionUseCoupon();
            if (pathRoute === PageRoute.HOME) {
              this.couponService.loadFetchCoupons();
              return;
            }

            if (pathRoute == PageRoute.MY_COUPON) {
              this.couponService.loadFetchMyCoupon();
              return;
            }

            if (pathRoute == PageRoute.COUPON_LIST) {
              this.couponService.loadFetchCouponList(this.amount, false);
              return;
            }
          }
        });
      }
    }
  }

  getCouponCard(index: number) {
    return this.couponCards.toArray()[index];
  }

  disableActionCard(element: ElementRef<any>, value: boolean = false) {
    if (value) {
      element.nativeElement.classList.add('disabled-click-action')
    } else {
      element.nativeElement.classList.remove('disabled-click-action')
    }
  }
}
