<div *ngIf="!(isLoading$ | async)" class="bg-standard">
    <div [class]="isAndroid ? ' android-device' : ''" class="loading-page">
        <div
            [class]="'header has-text-centered header-flex' + (withHeader ? '' : ' without-header') + (isAndroid ? ' android-header' : '')">
            <div class="has-text-left back-btn">
                <a [href]="callbackUrl | safe: 'url'" (click)="setStepRouteFlag(); setBackGATag()" class="link-item"
                    data-testid="link-callback-icon">
                </a>
            </div>
            <div class="header-title">
                <h3>เลือกคูปอง</h3>
            </div>
            <div class="header-temp-last">
            </div>
        </div>

        <div id="search-coupon-content">

            <div class="column container-coupon-search pb-0" #column>
                <div class="w-100">
                    <form-apply-coupon [applyCouponTemplate]="couponTemplate"
                        (onActionNavigateToApplyCoupon)="navigateToApplyCoupon()">
                    </form-apply-coupon>
                </div>
            </div>

            <div class="coupon-list-card"></div>
        </div>

        <div class="coupon-list-card"></div>
        <div id="coupon-list-content">
            <ng-container *ngIf="this.countTimeCoupon > 0 && this.countTimeCoupon < 3600">
                <countdown hidden [config]="{ leftTime: this.countTimeCoupon }" (event)="handleEvent($event)">
                </countdown>
            </ng-container>

            <div id="couponCardList" [class]="'notification pd-content' + (withHeader ? '' : ' mg-CNX')">
                <div *ngIf="isEmptyCoupon">
                    <div class="emptyCoupon">
                        <div class="container margin-top">
                            <div class="has-text-centered">
                                <img src="../../../assets/images/coupon_empty.png">
                                <p class="font-bold space-pt" data-testid="empty-coupon">
                                    ระบุโค้ดที่ช่องด้านบนเพื่อเก็บคูปอง
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="space"></div>
                <coupon-card-horizontal #couponCard [couponTemplate]="couponTemplate" [coupons]="couponList"
                    [selectedCoupon]="selectedCoupon$ | async" [couponChannel]="couponChannel" [userSOF]="userSOF"
                    [chooseCouponCallbackUrl]="chooseCouponCallbackUrl" [registerCallbackUrl]="registerCallbackUrl"
                    [processingCallbackUrl]="processingCallbackUrl"
                    (onActionNavigateToCouponDetail)="navigateToCouponDetail($event, true)"
                    (onActionPickCoupon)="pickCoupon($event.element, $event.couponId, $event.couponOrder, $event.campaignCode)"
                    (emitOpenDialogButtom)="openOverlay($event.coupon)" (onActionSetStepRoute)="setStepRouteFlag()"
                    (onActionUseCouponCopay)='setUseCopayGATag($event.eventLabel, $event.campaignCode)'
                    (onActionUseCouponPaymentTopup)="onActionUseCouponPaymentTopup($event.eventLabel, $event.campaignCode, $event.destination)"
                    (onActionUseCouponExternalURL)="navigateExternalURL($event.coupon , $event.externalUrl)"
                    (onActionSelectedCoupon)="onActionSelectedCoupon($event)"
                    (onActionPermissionError)="displayPermissionError($event.campaignCode,$event.permisionCoupon, CouponActionType.USE, $event?.isNavigate)"
                    (onActionSetToLoadFech)="setToLoadFech()">
                </coupon-card-horizontal>
            </div>

        </div>

        <app-animation-loading [couponloading]="isLoadingCollectCoupon$ | async">
        </app-animation-loading>

        <div #toastPopup class="toast-noti is-hidden">
            <table>
                <tr>
                    <td><img class="mr-2" src="../../../assets/icon/Icon-checked.png" width="25"></td>
                    <td><span>เก็บคูปองสำเร็จ</span></td>
                </tr>
            </table>
        </div>

    </div>
    <!-- <div class="loading-page is-hidden">
        <div class="pt-1 pl-1">
            <img class="rotating" src="../../../assets/images/coupon-loading.png" width="30" />
        </div>
    </div> -->
</div>